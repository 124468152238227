/**
 * @file
 * News
 *
 * Contains styles for news and news view modes.
 */
.news-detail{
  &__header{
    width: 100%;
  }
  &__data{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__author{
    &:before{
      content: "|";
      display: inline-block;
      vertical-align: top;
      margin: 0 12px;
    }
    a{
      text-decoration: none;
      color: $color-white;
      text-decoration: underline;
    }
  }
}
.news {
  &--full{
    padding: 27px 0 50px;
    @media (min-width: $break-medium) {
      padding: 40px 0 41px;
    }
    @media (min-width: $break-large) {
      padding: 70px 0 78px;
    }
    .container{
      margin: 0 auto 26px;
      @media (min-width: $break-medium) {
        margin: 0 auto 40px;
      }
      @media (min-width: $break-large) {
        margin: 0 auto 55px;
      }
    }
  }
  .quote {
    .container {
      margin: 0 auto;
    }
    &--full{
      @media (min-width: $break-large) {
        padding: 65px 0 63px;
        font-size: 25px;
        line-height: 1.44;
        margin: 0 0 57px;
      }
      .container{
        @media (min-width: $break-large) {
          max-width: 622px;
        }
      }
    }
    &__copy{
      @media (min-width: $break-large) {
        padding: 37px 0 22px;
      }
    }
  }
  &__content{
    .container{
      margin: 0 auto;
    }
  }
  &__tags{
    @media (min-width: $break-large) {
      padding: 33px 0 0;
    }
  }
  &__tags-label {
    font: 700 16px/1.2 $base-font;
    text-transform: uppercase;
    margin: 0 0 25px;
    @media (min-width: $break-medium) {
      margin: 0 64px 0 0;
    }
  }
  &__tags-wrapper{
    font-size: 15px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-start;
    }
    a{
      font-weight: normal;
      display: block;
    }
  }
  &__tags-list{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li{
      margin: 0 16px 0 0;
      @media (min-width: $break-medium) {
        margin: 0 31px 0 0;
      }
      &:last-child{
        margin: 0;
      }
    }
  }
  // Listing
  &--listing {
    padding: 22px 0 42px;
    font-size: 14px;
    line-height: 1.72;
    @media (min-width: $break-medium) {
      padding: 23px 0 42px;
    }
    @media (min-width: $break-large) {
      padding: 66px 0 77px;
      font-size: 15px;
      line-height: 1.67;
    }
    .container{
      max-width: 754px;
    }
    .news {
      &__item {
        margin: 0 0 45px;
        @media (min-width: $break-medium) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          margin: 0 0 33px;
        }
        @media (min-width: $break-large) {
          margin: 0 0 50px;
        }
      }
      &__image {
        margin: 0 0 10px;
        position: relative;
        @media (min-width: $break-medium) {
          width: 23.5%;
          margin: 0;
        }
        @media (min-width: $break-large) {
          width: 29.3%;
        }

        img {
          width: 100%;
        }
      }
      &__body{
        @media (min-width: $break-medium) {
          width: 76.5%;
          padding: 12px 0 0 25px;
        }
        @media (min-width: $break-large) {
          width: 70.7%;
          padding: 13px 0 0 28px;
        }
      }
      &__teaser{
        display: none;
        @media (min-width: $break-medium) {
          display: block;
        }
      }
      &__date{
        font-weight: normal;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 1.04px;
        margin: 0 0 13px;
        @media (min-width: $break-medium) {
          font-size: 15px;
          margin: 0 0 12px;
        }
        @media (min-width: $break-large) {
          font-size: 14px;
        }
      }
      &__title {
        @media (min-width: $break-medium) {
          margin: 0 0 9px;
        }
        h2 {
          margin: 0;
          font-size: 19px;
          line-height: 1.27;
          @media (min-width: $break-medium) {

          }
          @media (min-width: $break-large) {
            font-size: 25px;
          }
        }
        a{
          text-decoration: none;
          &:after{
            content: "\e900";
            font-family: 'icomoon';
            font-size: 12px;
            line-height: 1;
            font-weight: normal;
            color: $color-red;
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
  &__pagination{
    a{
      font-weight: normal;
      text-decoration: none;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    .ellipsis{
      margin: 0 9px;
    }
    >span{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  .paginate_button {
    margin: 0 9px;
    &:hover{
      text-decoration: none;
    }
    &.current {
      color: $color-grey2;
    }
    &.previous,
    &.next {
      font-size: 0;
      line-height: 0;
      color: $color-red;
      margin: 0 0 0 13px;
      &:before{
        position: relative;
        content: "\e90a";
        font-family: 'icomoon';
        display: block;
        font-size: 6px;
        line-height: 1;
        font-weight: 800;
        transform: rotate(90deg);
        z-index: -1;
      }
    }
    &.disabled {
      color: $color-grey2;
    }
    &.previous {
      margin: 0 13px 0 0;
      &:before{
        transform: rotate(-90deg);
      }
    }
  }
 }