/**
 * @file
 * Program List
 *
 * Styles for Program List display object.
 */

.program-list {
  background-color: $dark-blue;
  color: $color-white;
  padding: 34px 0;
  margin: 0;
  @media (min-width: $break-medium) {
    padding: 63px 0 33px;
  }
  @media (min-width: $break-large) {
    padding: 86px 0 60px;
  }
  &__header{
    @media (min-width: $break-medium) {
      margin: 0 0 22px;
    }
  }
  &__intro{
    margin: 0 0 20px;
    @media (min-width: $break-medium) {
      margin: 0 0 24px;
    }
  }
  &__content{
    font-size: 16px;
  }
  &__item {
    padding: 12px 0 14px;
    border-top: 1px solid $color-white;
    line-height: 1.5;
    @media (min-width: $break-medium) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0;
    }
    @media (min-width: $break-large) {
      padding: 26px 0 21px;
    }
  }
  &__program {
    flex-basis: 60%;
    font-size: 21px;
    line-height: 1.2;
    margin: 0 0 10px;
    @media (min-width: $break-medium) {
      font-size: 24px;
      margin: 0;
    }
    @media (min-width: $break-large) {
      font-size: 27px;
    }
    a{
      text-decoration: none;
      font-weight: normal;
      transition: color .3s;
      &:after{
        content: "\e900";
        font-family: 'icomoon';
        font-size: 12px;
        line-height: 1;
        font-weight: normal;
        color: $color-red;
        margin: 0 0 0 8px;
        transition: margin .3s;
      }
      @media (min-width: $break-large) {
        &:hover{
          color: $color-red;
          &:after{
            margin: 0 0 0 12px;
          }
        }
      }
    }
  }
  &__type{
    @media (min-width: $break-medium) {
      flex-basis: 28%;
    }
    @media (min-width: $break-large) {
      flex-basis: 34%;
    }
  }
  a{
    color: $color-white;
  }
}