.start-section{
  min-height: 216px;
  background-size: cover;
  background-color: $color-black;
  background-position: center center;
  background-repeat: no-repeat;
  color: $color-white;
  display: flex;
  position: relative;

  @media (min-width: $break-medium) {
    min-height: 314px;
  }
  @media (min-width: $break-large) {
    min-height: 516px;
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.54));
    height: 216px;
    @media (min-width: $break-medium) {
      height: 289px;
    }
  }
  .container{
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1;
  }
  h2{
    font: 50px/1 Anton, Arial, Helvetica, sans-serif;
    margin: 0 0 27px;
    @media (min-width: $break-medium) {
      margin: 0 0 31px;
    }
    @media (min-width: $break-large) {
      font-size: 80px;
      margin: 0 0 88px;
    }
  }
}