/**
 * @file
 * Home slider
 *
 * Contains styles for home slider object.
 */

.home-slider{
  padding: 56px 0 44px;
  margin: 0;
  @media (min-width: $break-medium) {
    padding: 80px 0 92px;
  }
  @media (min-width: $break-large) {
    padding: 71px 0 87px;
  }
  .container{
    max-width: 1040px;

    @media screen and (min-width: $break-medium) {
      position: relative;
    }

    &:before {
      @media screen and (min-width: $break-medium) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background-color: $color-white;
        z-index: 1;
      }

      @media screen and (min-width: $break-large) {
        left: calc(-1 * (100vw - 1040px) / 2);
        width: calc((100vw - 1000px) / 2);
      }
    }
  }
  h2{
    font-size: 30px;
    @media (min-width: $break-medium) {
      font-size: 40px;
    }
    @media (min-width: $break-large) {
      font-size: 50px;
    }
  }
  &__header{
    margin: 0 0 27px;
    @media (min-width: $break-large) {
      margin: 0 0 43px;
    }
  }
  &__item {
    padding: 0 0 168px;
    @media (min-width: $break-medium) {
      display: flex !important;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0;
    }
  }
  &__wrapper{
    &:after{
      content: "";
      position: absolute;
      bottom: 71px;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 21px;
      background-image: radial-gradient(circle at 48% -170px, #eabfc0, #ffffff 200px);
      @media (min-width: $break-medium) {
        width: 280px;
        height: 41px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
        transform: none;
        left: 0;
        bottom: auto;
        top: 100%;
      }
      @media (min-width: $break-large) {
        width: 349px;
        height: 41px;
      }
    }
  }
  &__content{
    @media (min-width: $break-medium) {
      width: 52.8%;
      padding: 41px 0 0 40px;
    }
    @media (min-width: $break-large) {
      width: 45.8%;
      padding: 35px 0 0 58px;
    }
  }
  &__img {
    padding-top: 104%;
    position: relative;
    @media (min-width: $break-medium) {
      padding-top: 47.3%;
      width: 47.2%;
    }
    @media (min-width: $break-large) {
      padding-top: 54.3%;
      width: 54.2%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  blockquote{
    position: relative;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 29px 0 0;
    font-size: 22px;
    line-height: 1.32;
    @media (min-width: $break-medium) {
      padding: 0;
      margin: 0 0 23px;
    }
    @media (min-width: $break-large) {
      font-size: 26px;
      line-height: 1.31;
      margin: 0 0 43px;
    }
  }
  cite{
    display: block;
    font-style: normal;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: .26px;
    border-bottom: 1px solid $color-black;
    padding: 0 0 19px;
    margin: 0 0 19px;
    @media (min-width: $break-large) {
      font-size: 16px;
      padding: 0 0 37px;
      margin: 0 0 41px;
    }
    span{
      display: block;
      margin: 0 0 5px;
      &:last-child{
        margin: 0;
      }
    }
  }
  &__name{
    font-weight: 600;
  }
  q{
    display: block;
  }
  .slick-arrow{
    top: auto;
    bottom: 112px;
    transform: none;
    width: auto;
    height: auto;
    color: $color-black;
    text-indent: 0;
    @media (min-width: $break-medium) {
      width: 66px;
      height: 66px;
      left: 215px;
      bottom: 0;
    }
    @media (min-width: $break-large) {
      left: 349px;
    }
    &:after{
      content: "\e909";
      font-family: 'icomoon';
      display: block;
      font-size: 20px;
      line-height: 1;
      position: static;
      height: auto;
      width: auto;
      background: none;
      transform: none;
    }
    &.slick-prev{
      @media (min-width: $break-medium) {
        left: 50px;
      }
      @media (min-width: $break-large) {
        left: 190px;
      }
      &:after{
        transform: rotate(-180deg);
      }
    }
    &.slick-disabled{
      &:after{
        background: none;
      }
      @media (min-width: $break-medium) {
        background: $dark-blue;
        color: $color-white;
      }
    }
  }
  .slick-dots{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 112px;
    left: 0;
    width: 100%;
    @media (min-width: $break-medium) {
      position: absolute;
      bottom: 23px;
      left: 140px;
      width: auto;
    }
    @media (min-width: $break-large) {
      left: 275px;
    }
    button{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      &:after{
        width: 4px;
        height: 4px;
        background-color: #182033;
      }
    }
  }
  .cta{
    &--button{
      margin: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      @media (min-width: $break-medium) {
        position: static;
      }
    }
  }
  .slick-list{
    @media (min-width: $break-medium) {
      overflow: visible;
    }
  }
  .slick-track{
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !important;
    display: -o-flex !important;
    display: flex !important;
    @media (min-width: $break-medium) {
      display: block !important;
    }
  }
  .slick-slide{
    height: inherit !important;
    @media (min-width: $break-medium) {
      width: 595px;
      margin: 0 0 0 35px;
      height: 100% !important;
    }
    @media (min-width: $break-large) {
      width: 765px;
      margin: 0 0 0 138px;
    }
  }
}