/**
 * Mixins
 * Contains global SCSS mixins
 */

/// Clearfix
@mixin clearfix() {
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

/// Image replacement
@mixin ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;                     // Remove the default border from elements like <button>
  font: 0/0 a;                   // Crush the text down to take up no space
  text-shadow: none;             // Remove any text shadows
  color: transparent;            // Hide any residual text in Safari 4 and any mobile devices that may need it
  background-color: transparent; // Hide the default background color on elements like <button>
}

/// Invisible elements
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

/// Show an invisible element
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/// Show invisible element when focused
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/// Full width mixin to break content out into gutters
@mixin full-width() {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

/// Content Gutter
@mixin gutter {
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: $break-medium) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (min-width: $break-large) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/// Maximum width Object
@mixin content-full {
  max-width: $content-max;
  margin-left: auto;
  margin-right: auto;
}

/// Gradient overlay
@mixin gradient-overlay {
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba($color-white, 0) 0%, rgba($color-white, 0) 45%, $color-black 100%);
    content: '';
  }
}

/// Placeholder mixin
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}


/// CTA mixin
@mixin cta {
  margin: 1.5rem 0 0;

  a {
    display: inline-block;
    padding: 0.125rem 0.5rem;
    background: $color-black;
    color: $color-white;
  }
}