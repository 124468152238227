/**
 * @file
 * Video
 *
 * Contains styles for inline video objects.
 */

.vid-inline {
  overflow: hidden;
  &--full{
    padding: 0 20px;
    margin: 0 auto 40px;
    max-width: 884px;
    position: relative;
    overflow: visible;
    &:after{
      content: "";
      position: absolute;
      bottom: -37px;
      left: 20px;
      width: 124px;
      height: 14px;
      background-image: radial-gradient(circle at 48% -118px, #eabfc0, #ffffff 140px);
      @media (min-width: $break-medium) {
        width: 267px;
        height: 31px;
        left: 50px;
        background-image: radial-gradient(circle at 48% -160px, #eabfc0, #ffffff 200px);
      }
      @media (min-width: $break-large) {
        width: 349px;
        height: 41px;
        bottom: -55px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
      }
    }
    @media (min-width: $break-medium) {
      padding: 0 50px;
      margin: 0 auto 56px;
    }
    @media (min-width: $break-large) {
      margin: -34px auto 91px;
    }
  }
  &__container {
    position: relative;
  }
  &__cover {
    position: relative;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease;
    &:focus {
      outline-offset: -4px;
    }
    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }
      @media (min-width: $break-large) {
        &:after{
          width: 66px;
          height: 66px;
        }
      }
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      
    }
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 0 3px 5px;
      border-color: transparent transparent transparent $color-white;
      z-index: 2;
      margin: 0 0 0 2px;
      @media screen and (min-width: $break-medium) {
        border-width: 6px 0 6px 10.4px;
      }
      @media screen and (min-width: $break-large) {
        border-width: 8px 0 8px 13.9px;
      }
    }
    &:after {
      width: 22px;
      height: 22px;
      border: 1px solid $color-white;
      border-radius: 100%;
      transition: 0.2s all;
      @media screen and (min-width: $break-medium) {
        width: 47px;
        height: 47px;
        border-width: 2px;
      }
      @media screen and (min-width: $break-large) {
        width: 62px;
        height: 62px;
      }
    }
    &.js-loading {
      &:hover {
        &:before {
          opacity: 1;
        }
      }
      &:before {
        margin: 0;
        height: 60px;
        width: 60px;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns:svg=\'http://www.w3.org/2000/svg\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' version=\'1.0\' width=\'60px\' height=\'60px\' viewBox=\'0 0 128 128\' xml:space=\'preserve\'%3e%3cg%3e%3cpath d=\'M78.75 16.18V1.56a64.1 64.1 0 0 1 47.7 47.7H111.8a49.98 49.98 0 0 0-33.07-33.08zM16.43 49.25H1.8a64.1 64.1 0 0 1 47.7-47.7V16.2a49.98 49.98 0 0 0-33.07 33.07zm33.07 62.32v14.62A64.1 64.1 0 0 1 1.8 78.5h14.63a49.98 49.98 0 0 0 33.07 33.07zm62.32-33.07h14.62a64.1 64.1 0 0 1-47.7 47.7v-14.63a49.98 49.98 0 0 0 33.08-33.07z\' fill=\'%23000000\' fill-opacity=\'1\'/%3e%3canimateTransform attributeName=\'transform\' type=\'rotate\' from=\'0 64 64\' to=\'-90 64 64\' dur=\'800ms\' repeatCount=\'indefinite\'%3e%3c/animateTransform%3e%3c/g%3e%3c/svg%3e');
        background-position: center;
        background-size: contain;
        border: 0;
        transform: translate(-50%, -50%);
      }
      &:after {
        display: none;
      }
    }
    &.js-hide {
      display: block;
      z-index: 1;
      opacity: 0;
    }
  }
  &__embed {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    &.js-show {
      z-index: 2;
      opacity: 1;
    }
  }
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
  &__caption {
    font: 12px/1.6 $base-font;
    margin: 7px 0 0;
    @media (min-width: $break-medium) {
      margin: 15px 0 0;
    }
    @media (min-width: $break-large) {
      margin: 19px 0 0;
    }
  }
}