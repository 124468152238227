/**
 * @file
 * Section
 *
 * Base styles for all section content.
 */

.section {
  margin: 0 0 30px;
  @media (min-width: $break-medium) {
    margin: 0 0 44px;
  }
  @media (min-width: $break-large) {
    margin: 0 0 115px;
  }
  &__header {
    margin: 0 0 15px;
    @media (min-width: $break-large) {
      margin: 0 0 16px;
    }
    h2 {
      margin: 0;
    }
  }
  &__intro {
    margin: 0 0 7px;
    @media (min-width: $break-medium) {
      margin: 0 0 27px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 42px;
      max-width: 780px;
    }
  }
  &__cta {
    @include cta;
    text-align: center;
  }
}
