.wysiwyg{
  padding: 0 0 9px;
  @media (min-width: $break-medium) {
    padding: 0 0 18px;
  }
  @media (min-width: $break-large) {
    padding: 0;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    @media (min-width: $break-medium) {
      padding: 0 0 0 24px;
    }
    @media (min-width: $break-large) {
      padding: 0 0 0 33px;
    }
    li {
      margin: 0 0 6px;
      &:last-child{
        margin: 0;
      }
      @media (min-width: $break-large) {
        margin: 0 0 10px;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0 0 28px;
    list-style: none;
    @media (min-width: $break-medium) {
      padding: 0 0 0 36px;
      margin: 0 0 27px;
    }
    @media (min-width: $break-large) {
      padding: 0 0 0 32px;
      margin: 0 0 40px;
    }
    > li {
      padding: 0 0 0 31px;
      position: relative;
      margin: 0 0 4px;
      @media (min-width: $break-large) {
        margin: 0 0 7px;
      }
      &:last-child {
        margin: 0;
      }
      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 1px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-black;
      }
    }
    ul {
      padding: 4px 0 0;
      margin: 0;
      @media (min-width: $break-large) {
        padding: 4px 0 0;
      }
      li {
        padding: 0 0 0 22px;
        &:before {
          width: 3px;
          height: 3px;
          left: 4px;
          top: 12px;
        }
      }
    }
  }
  hr{
    border-color: $color-black;
    border-width: 1px 0 0;
    margin: 28px 0 30px;
    @media (min-width: $break-medium) {
      margin: 42px 0;
    }
    @media (min-width: $break-large) {
      margin: 60px 0 55px;
    }
  }
  .container{
    margin: 0 auto 30px;
    @media (min-width: $break-medium) {
      margin: 0 auto 40px;
    }
    @media (min-width: $break-large) {
      margin: 0 auto 84px;
    }
  }
  .quote,
  .img-inline--full{
    .container{
      margin: 0 auto;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    line-height: 1.2;
    min-width: 640px;
    @media (min-width: 640px) {
      min-width: 700px;
    }
    @media (min-width: $break-large) {
      min-width: 0;
    }
    a {
      color: $color-black;
      font-weight: normal;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .table-block {
    position: relative;
    padding: 0;
    margin: 21px -20px 38px 0;
    @media (min-width: $break-medium) {
      margin: 22px -50px 55px 0;
      clear: both;
    }
    @media (min-width: $break-large) {
      margin: 0 0 75px;
    }
    &__holder {
      overflow: auto;
      &.js-scroll--right {
        .table-block__next {
          display: block;
        }
      }
      &.js-scroll--left {
        .table-block__prev {
          display: block;
        }
      }
    }
    &__prev,
    &__next {
      display: none;
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 73px;
      transform: translateY(-50%);
      left: 16;
      width: 46px;
      height: 46px;
      border: none;
      background: none;
      padding: 0;
      @media (min-width: $break-medium) {
        top: 80px;
      }
      &:after {
        transform: rotate(90deg);
        display: inline-block;
        vertical-align: top;
        content: '\e90a';
        color: $color-red;
        font-family: 'icomoon';
        font-size: 16px;
        line-height: 1;
      }
    }
    &__next {
      left: auto;
      right: 36px;
      @media (min-width: $break-medium) {
        right: 8px;
      }
    }
    &__prev {
      &:after {
        content: '\e90a';
        transform: rotate(-90deg);
      }
    }
  }
  th {
    text-align: left;
    padding: 5px 0;
    width: auto;
    &:first-child {
      width: 39%;
    }
    &:last-child {
      width: 27.5%;
    }
  }
  thead {
    font-size: 16px;
    tr {
      border-bottom: 2px solid $color-black;
    }
  }
  tbody{
    tr{
      &:nth-child(2n){
        background-color: $color-grey;
      }
    }
  }
  td {
    padding: 18px 15px 17px 0;
    vertical-align: top;
  }
  tr {
    border-bottom: 1px solid $color-black;
  }
  .form {
    padding: 20px 0 0;
    @media (min-width: $break-large) {
      padding: 8px 0 0;
    }
    &__item {
      margin: 0 0 32px;
    }
    &--actions {
      margin: 0;
    }
  }
}