.hero-inner{
  padding: 100px 0 20px;
  min-height: 308px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  background-color: $dark-blue;
  position: relative;
  @media (min-width: $break-medium) {
    padding: 120px 0 33px;
  }
  @media (min-width: $break-large) {
    min-height: 527px;
    padding: 150px 0 55px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 527px;
    }
  }
  &__calendar,
  &__news{
    @media (min-width: $break-large) {
      min-height: 366px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 366px;
      }
    }
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 114px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    opacity: .85;
    @media (min-width: $break-large) {
      height: 197px;
    }
  }
  >.container{
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
  }
  h1{
    color: $color-white;
    &:only-child{
      margin: 0;
    }
  }
  &_no-bg{
    min-height: 240px;
    @media (min-width: $break-medium) {
      min-height: 265px;
    }
    @media (min-width: $break-large) {
      min-height: 370px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 370px;
      }
    }
    &:after{
      display: none;
    }
  }
  &__event-detail{
    color: $color-white;
    min-height: 214px;
    padding: 20px 0 28px;
    @media (min-width: $break-medium) {
      min-height: 280px;
      padding: 20px 0 51px;
    }
    @media (min-width: $break-large) {
      min-height: 368px;
      padding: 40px 0 70px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 368px;
      }
    }
    .container{
      padding: 0 10px 0 20px;
      @media (min-width: $break-medium) {
        padding: 0 50px;
      }
      @media (min-width: $break-large) {
        padding: 0 20px;
      }
    }
    h1{
      font: 500 20px/1.2 $base-font;
      @media (min-width: $break-medium) {
        font-size: 28px;
      }
      @media (min-width: $break-large) {
        font-size: 35px;
      }
    }
  }
  &__news-detail{
    color: $color-white;
    min-height: 204px;
    font-size: 13px;
    line-height: 1.2;
    padding: 100px 0 31px;
    @media (min-width: $break-medium) {
      min-height: 260px;
      padding: 120px 0 42px;
    }
    @media (min-width: $break-large) {
      min-height: 366px;
      padding: 150px 0 83px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 366px;
      }
    }
    h1{
      font-size: 24px;
      line-height: 1.2;
      font-weight: 700;
      margin: 0 0 16px;
      @media (min-width: $break-medium) {
        font-size: 35px;
        margin: 0 0 21px;
      }
    }
  }
  &_no-header{
    h1{
      display: none;
      @media (min-width: $break-medium) {
        display: block;
      }
    }
  }
}