/**
 * @file
 * Form
 *
 * Contains styles for forms in main content region.
 */
.main .form {
  font-size: 15px;
  line-height: 1.2;
  &__item:not(.form--fieldset) {
    label {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      margin: 0 0 14px;
      @media (min-width: $break-large) {
        margin: 0 0 15px;
        font-size: 16px;
      }
    }
  }
  &__item{
    &.form--textarea{
      margin: 0 0 31px;
      label{
        margin: 0 0 21px;
      }
    }
    &.form--fieldset{
      margin: 0 0 13px;
      @media (min-width: $break-large) {
        margin: 0 0 10px;
      }
    }
  }
  &--select{
    position: relative;
    &:after{
      content: "\e90a";
      font-family: 'icomoon';
      position: absolute;
      left: 211px;
      bottom: 14px;
      font-size: 4px;
      transform: rotate(180deg);
      line-height: 1;
      font-weight: 700;
    }
  }
  &--fieldset{
    padding: 0;
    margin: 0;
    padding: 0;
    border: none;
  }
  &__checkbox,
  &__radio{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 0 23px;
    label{
      margin: 0 0 0 14px;
      position: relative;
      &:before{
        transition: opacity .3s;
        content: '';
        opacity: 0;
        position: absolute;
        top: 5px;
        left: -29px;
        cursor: pointer;
        border: 1px solid $color-white;
        border-width: 0 0 2px 2px;
        width: 8px;
        height: 3px;
        transform: rotate(-45deg);
      }
    }
  }
  &__radio{
    border-radius: 50%;
    label{
      &:before{
        left: -26px;
        top: 50%;
        transform: translateY(-50%);
        margin: -1px 0 0;
        border: none;
        width: 5px;
        height: 5px;
        background-color: $color-black;
        border-radius: 50%;
      }
    }
  }
  &--actions{
    padding: 8px 0 0;
    @media (min-width: $break-medium) {
      padding: 4px 0 0;
    }
    @media (min-width: $break-large) {
      padding: 24px 0 0;
    }
  }
}
input[type="search"]{
  -webkit-appearance: none;
  border-radius: 0;
  @include placeholder{
    color: $color-black;
    opacity: 1;
  }
}
input[type="text"],
input[type="email"],
select{
  display: block;
  width: 222px;
  border: solid $color-black;
  border-width: 0 0 1px;
  height: 30px;
  margin: 0;
  padding: 2px 0;
  font: 15px/1.2 $base-font;
  background: none;
  @include placeholder{
    color: $color-black;
  }
}
textarea{
  width: 100%;
  height: 144px;
  border: 1px solid $color-black;
  resize: none;
  padding: 10px;
  display: block;
}
legend{
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 23px;
  @media (min-width: $break-large) {
    font-size: 16px;
  }
}
[type='checkbox']{
  width: 20px;
  height: 20px;
  border: 1px solid $color-black;
  transition: background-color .3s;
  &:checked{
    background-color: $color-black;
    &+label{
      &:before{
        opacity: 1;
      }
    }

    &::-ms-check {
      display: none;
    }
  }
}
[type='radio']{
  width: 20px;
  height: 20px;
  border: 1px solid $color-black;
  border-radius: 50%;
  &:checked{
    &+label{
      &:before{
        opacity: 1;
      }
    }
  }
}
button[type="submit"]{
  padding: 0;
  margin: 0;
  width: 158px;
  height: 47px;
  border: none;
  background: $dark-blue;
  letter-spacing: .26px;
  font-size: 14px;
  line-height: 1.2;
  color: $color-white;
  transition: background-color .3s;
  transform: perspective(1px) translateZ(0);
  position: relative;
  &:before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-red;
    transform: scaleX(0);
    transform-origin: 50%;
    transition: .3s;
  }
  &:hover{
    color: $color-white;
    &:before{
      transform: scaleX(1);
    }
  }
  @media (min-width: $break-large) {
    width: 211px;
    height: 62px;
    letter-spacing: normal;
    font-size: 18px;
  }
}