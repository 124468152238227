/**
 * @file
 * Generic Block: 3 Column
 *
 * Styles for Generic Block: 3 Column object.
 */

.gen3col {
  background-color: $color-grey;
  padding: 33px 0 25px;
  margin: 0;
  @media (min-width: $break-medium) {
    padding: 44px 0 46px;
  }
  @media (min-width: $break-large) {
    padding: 83px 0 74px;
  }
  .column{
    @media (min-width: $break-medium) {
      @media (min-width: $break-medium) {
        display: block;
      }
      @media (min-width: $break-large) {
        display: flex;
      }
    }
    &__col{
      &:last-child{
        border: none;
        padding: 0;
      }
      @media (min-width: $break-medium) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0 0 28px;
        margin: 0 0 20px;
        &:last-child{
          margin: 0;
        }
      }
      @media (min-width: $break-large) {
        display: block;
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    &__img{
      @media (min-width: $break-medium) {
        margin: 0;
        width: 23.5%;
      }
      @media (min-width: $break-medium) and (max-width: $break-large - 1) {
        position: relative;
        height: 0;
        padding-top: 23.5%;
      }
      @media (min-width: $break-large) {
        width: 100%;
        margin: 0 0 9px;
      }
      img {
        @media (min-width: $break-medium) and (max-width: $break-large - 1) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__content{
      @media (min-width: $break-medium) {
        width: 76.5%;
        padding: 12px 0 0 25px;
      }
      @media (min-width: $break-large) {
        padding: 0;
        width: 100%;
      }
    }
    &__subtitle{
      @media (min-width: $break-medium) {
        font-size: 15px;
        letter-spacing: 1.3px;
        margin: 0 0 13px;
      }
      @media (min-width: $break-large) {
        margin: 0 0 18px;
      }
    }
    &__title{
      @media (min-width: $break-medium) {
        margin: 0 0 10px;
      }
      @media (min-width: $break-large) {
        margin: 0 0 8px;
      }
      h3{
        @media (min-width: $break-medium) {
          font-size: 19px;
        }
        @media (min-width: $break-large) {
          font-size: 20px;
        }
      }
    }
  }
  &__intro{
    margin: 0 0 7px;
    @media (min-width: $break-medium) {
      margin: 0 0 37px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 47px;
    }
  }
}