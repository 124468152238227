.alert {
  display: block;
  padding: 54px 20px 30px;
  font-size: 15px;
  line-height: 1.67;
  @media (min-width: $break-medium) {
    padding: 48px 50px 37px;
  }
  @media (min-width: $break-large) {
    padding: 79px 64px 65px;
    font-size: 18px;
    line-height: 1.56;
  }
  &-display {
    padding: 0;
    h1,
    &__content {
      padding: 0 15px;
      @media (min-width: $break-medium) {
        padding: 0 20px;
      }
    }
    &__content {
      margin: 0 0 5rem 0;
    }
  }
  &__content {
    @media (min-width: $break-large) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  &__message {
    margin: 0 0 19px;
    @media (min-width: $break-medium) {
      margin: 0 0 28px;
    }
    @media (min-width: $break-large) {
      margin: 0;
      width: 57%;
    }
  }
  &__icon {
    &:before {
      position: absolute;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      left: -2em;
      width: 1.5em;
      height: 1.5em;
      background: url('/assets/icons/error.svg') no-repeat center left;
      background-size: contain;
      content: '';
    }
  }
  &__title {
    position: relative;
    line-height: 1.2;
    font-size: 23px;
    font-weight: 700;
    margin: 0 0 15px;
    @media (min-width: $break-large) {
      font-size: 27px;
      margin: 0 0 21px;
    }
  }
  &__cta {
    color: $color-white;
    background-color: $color-black;
    margin: 0;
    text-align: center;
    font-size: 14px;
    min-width: 0;
    padding: 16px 40px;
    @media (min-width: $break-large) {
      margin: 0 6% 0 0;
      font-size: 18px;
      min-width: 262px;
      padding: 28px 40px;
    }
    &:before{
      background-color: $color-red;
    }
  }
  &__close{
    position: absolute;
    top: 18px;
    right: 16px;
    border: none;
    padding: 0;
    background: none;
    width: 26px;
    height: 26px;
    &:hover{
      &:before,
      &:after{
        background: $color-red;
      }
    }
    @media (min-width: $break-medium) {
      top: 26px;
      right: 23px;
    }
    @media (min-width: $break-large) {
      top: 21px;
      right: 21px;
    }
    &:before,
    &:after{
      content: "";
      background: $color-black;
      position: absolute;
      transform: rotate(45deg);
      top: 11px;
      left: 0;
      right: 0;
      height: 5px;
      transition: all 0.2s linear;
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  &--minor {
    background-color: $color-alert-minor;
  }
  &--medium {
    background-color: $color-alert-medium;
  }
  &--major {
    background-color: $color-alert-major;
  }

  + header {
    position: relative;
  }
}