/**
 * @file
 * Page
 *
 * Contains styles for page wrapper layout.
 */

.container {
  padding: 0 20px;
  max-width: 720px;
  margin: 0 auto;
  @media (min-width: $break-medium) {
    padding: 0 50px;
  }
  @media (min-width: $break-large) {
    padding: 0 20px;
  }
  .index & {
    max-width: 1040px;
  }
  &_big{
    max-width: 940px;
  }
}
.wrapper{
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}