/**
 * @file
 * Typography
 * Base typography for the theme
 */

//## Headers
h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 10px;
  @media (min-width: $break-medium) {
    font-size: 40px;
    line-height: 1.13;
  }
  @media (min-width: $break-large) {
    font-size: 60px;
    line-height: 1.08;
  }
}
h2 {
  margin: 0 0 10px;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.18;
  @media (min-width: $break-medium) {
    font-size: 30px;
    line-height: 1.23;
  }
  @media (min-width: $break-large) {
    font-size: 40px;
    line-height: 1;
    margin: 0 0 28px;
  }
}
h3 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.29;
  margin: 0 0 10px;
  @media (min-width: $break-medium) {
    font-size: 24px;
    line-height: 1.21;
  }
  @media (min-width: $break-large) {
    font-size: 27px;
    line-height: 1.26;
  }
}
h4 {
  font-size: 19px;
  font-weight: 700;
  line-height: 1.26;
  margin: 0 0 10px;
  @media (min-width: $break-medium) {
    font-size: 21px;
    line-height: 1.29;
  }
  @media (min-width: $break-large) {
    font-size: 25px;
    line-height: 1.24;
  }
}
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 0 10px;
  @media (min-width: $break-medium) {
    font-size: 18px;
    line-height: 1.33;
  }
  @media (min-width: $break-large) {
    font-size: 18px;
    line-height: 1.33;
    margin: 0 0 16px;
  }
}
h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  margin: 0 0 10px;
  @media (min-width: $break-medium) {
    font-size: 14px;
    line-height: 1.29;
  }
  @media (min-width: $break-large) {
    font-size: 16px;
    line-height: 1.13;
    margin: 0 0 16px;
  }
}
//## Paragraphs
p {
  margin: 0 0 28px;
  &:empty {
    display: none;
  }
}
strong {
  font-weight: 700;
}