/**
 * Base theme styles
 * This file contains theme base styles.
 *
 * For example:
 * - WYSIWYG styles and layouts
 * - Images and Videos
 * - Tables
 */

html {
  font-size: 16px;
  @media (min-width: $break-large) {
    font-size: 18px;
  }
}

body {
  font-family: $base-font;
  font-weight: 400;
  line-height: 1.63;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @media (min-width: $break-large) {
    line-height: 1.6;
  }
  //** Fixed class to prevent scrolling
  &.js-fixed {
    overflow: hidden;
  }
}

//## Remove margin on last elements
* >  {
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
}

//## Visually hidden
.visually-hidden {
  @include element-invisible;
}

//## Skip to content
a.skip-link {
  position: absolute;
  left: 0;
  top: 0;
  color: $color-black;

  &.visually-hidden {
    @include element-focusable;

    &:focus {
      background-color: $color-white;
      padding: 3px 10px;
      position: absolute !important;
      z-index: 10000;
    }
  }
}
//## Floated content
.float-right {
  float: right;
}
.float-left {
  float: left;
}
//## Links
a {
  font-weight: 700;
  color: $color-black;
  transition: color .3s;
  cursor: pointer;
  &:hover {
    color: $color-red;
  }
}
//## Images
img {
  display: block;
  max-width: 100%;
  height: auto;
}
figure {
  margin: 0;
}
figcaption {
  margin: 0.5rem 0 0;
  font-style: italic;
  font-size: .75rem;
}
//## Inline images
.img-inline {
  &--full {
    margin: 0 auto 57px;
    padding: 0 20px;
    max-width: 824px;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      bottom: -37px;
      left: 20px;
      width: 124px;
      height: 14px;
      background-image: radial-gradient(circle at 48% -118px, #eabfc0, #ffffff 140px);
      @media (min-width: $break-medium) {
        width: 267px;
        height: 31px;
        left: 50px;
        background-image: radial-gradient(circle at 48% -160px, #eabfc0, #ffffff 200px);
      }
      @media (min-width: $break-large) {
        width: 349px;
        height: 41px;
        bottom: -55px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
      }
    }
    @media (min-width: $break-medium) {
      margin: 0 auto 54px;
      padding: 0 50px;
    }
    @media (min-width: $break-large) {
      margin: 0 auto 92px;
      padding: 0 20px;
    }
  }
  &--float {
    &.float-right,
    &.float-left {
      margin: 0 0 25px;
      @media (min-width: $break-medium) {
        margin: 9px 0 30px 20px;
        width: 51.8%;
      }
      @media (min-width: $break-large) {
        display: table;
        margin: 29px -147px 30px 100px;
        width: auto;
      }
      figcaption {
        @media (min-width: $break-large) {
          display: table-caption;
          caption-side: bottom;
        }
      }
    }
    &.float-left {
      @media (min-width: $break-medium) {
        margin: 9px 20px 30px 0;
        width: 51.8%;
      }
      @media (min-width: $break-large) {
        margin: 29px 100px 30px -147px;
        width: auto;
      }
    }
  }
  figcaption{
    font: 12px/1.6 $base-font;
    margin: 7px 0 0;
    @media (min-width: $break-medium) {
      margin: 14px 0 0;
    }
    @media (min-width: $break-large) {
      margin: 19px 0 0;
    }
  }
  .container{
    max-width: 824px;
  }
}
.quote{
  &--full{
    margin: 0 0 42px;
    background-color: $dark-blue;
    color: $color-white;
    font-size: 23px;
    line-height: 1.48;
    padding: 60px 0 56px;
    @media (min-width: $break-medium) {
      padding: 64px 0;
      font-size: 25px;
      line-height: 1.44;
      margin: 0 0 49px;
    }
    @media (min-width: $break-large) {
      font-size: 36px;
      line-height: 1.42;
      padding: 94px 0 75px;
      margin: 0 0 80px;
    }
    .container{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 0;
        right: 20px;
        left: 20px;
        border-top: 1px solid $color-white;
        @media (min-width: $break-medium) {
          right: 50px;
          left: 50px;
        }
        @media (min-width: $break-large) {
          right: 20px;
          left: 20px;
        }
      }
    }
  }
  &__copy{
    padding: 38px 0 17px;
    @media (min-width: $break-medium) {
      padding: 38px 0 21px;
    }
    @media (min-width: $break-large) {
      padding: 43px 0 67px;
    }
  }
  &__attr{
    font-weight: 600;
    margin: 0 0 5px;
  }
  footer{
    letter-spacing: .3px;
    font-size: 16px;
    line-height: 1.2;
  }
}
//## Tables
table {
  clear: both;
}
.cta{
  &--button{
    text-decoration: none;
    color: $color-white;
    background-color: $color-red;
    font: 15px/1.2 $base-font;
    display: inline-block;
    padding: 22px 40px;
    min-width: 214px;
    letter-spacing: .28px;
    transform: perspective(1px) translateZ(0);
    position: relative;
    @media (min-width: $break-medium) {
      margin: 0 0 13px;
    }
    @media (min-width: $break-large) {
      min-width: 262px;
      padding: 28px 63px;
      font-size: 18px;
      margin: 0 0 23px;
    }
    &:before{
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $dark-blue;
      transform: scaleX(0);
      transform-origin: 50%;
      transition: .3s;
    }
    &:hover{
      color: $color-white;
      &:before{
        transform: scaleX(1);
      }
    }
  }
  &--link{
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.2;
    color: $color-red;
    letter-spacing: .42px;
    @media (min-width: $break-medium) {
      margin: 0 0 15px;
    }
    @media (min-width: $break-large) {
      font-size: 18px;
    }
    &:after{
      content: "\e900";
      font-family: 'icomoon';
      font-size: 12px;
      line-height: 1;
      font-weight: normal;
      margin: 0 0 0 9px;
      @media (min-width: $break-large) {
        transition: margin .3s;
        position: relative;
        top: -1px;
      }
    }
    &:hover{
      @media (min-width: $break-large) {
        color: $dark-blue;
        &:after{
          margin: 0 0 0 13px;
        }
      }
    }
  }
}
button{
  font-family: $base-font;
}

// Program Detail intro margin fix
.main--program-full {
  .page-intro__title {
    margin-bottom: 0;
  }
}