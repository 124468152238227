.footer{
  .cta-bar{
    background-color: $color-red;
    @extend %listreset;
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 33px;
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      margin: 0 0 40px;
    }
    @media (min-width: $break-large) {
      font-size: 20px;
      margin: 0 0 67px;
    }
    a{
      background-color: $color-red;
      display: block;
      text-decoration: none;
      color: $color-white;
      font-weight: 500;
      padding: 33px 10px;
      position: relative;
      @media (min-width: $break-medium) {
        position: relative;
        transform: perspective(1px) translateZ(0);
        padding: 21px 10px;
        &:before{
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $dark-blue;
          transform: scaleX(0);
          transform-origin: 50%;
          transition: .3s;
        }
        &:hover{
          &:before{
            transform: scaleX(1);
          }
        }
      }
      @media (min-width: $break-large) {
        padding: 37px 10px;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        left: 15px;
        height: 1px;
        background-color: #cecece;
        @media (min-width: $break-medium) {
          top: 18px;
          bottom: 13px;
          left: 0;
          right: auto;
          height: auto;
          width: 1px;
        }
      }
    }
    li{
      @media (min-width: $break-medium) {
        width: 33.3333333%;
      }
      &:first-child{
        a{
          &:after{
            display: none;
          }
        }
      }
    }
  }
  .container{
    max-width: 1040px;
  }
  .logo{
    width: 178px;
    margin: 0 0 39px;
    @media (min-width: $break-medium) {
      flex-shrink: 0;
      margin: 0;
      width: 134px;
    }
    @media (min-width: $break-large) {
      width: 179px;
    }
    a{
      display: block;
      position: relative;
      height: 113px;
      @media (min-width: $break-medium) {
        height: 85px;
      }
      @media (min-width: $break-large) {
        height: 113px;
      }
    }
    img{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .3s, visibility .3s;
    }
    .hover{
      opacity: 0;
      visibility: hidden;
      height: 114px;
    }
    &:hover{
      img{
        opacity: 0;
        visibility: hidden;
        &.hover{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__nav-block{
    border-bottom: 1px solid $color-black;
    padding: 0 0 15px;
    position: relative;
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-start;
      padding: 0 0 33px;
    }
    @media (min-width: $break-large) {
      padding: 0 0 46px;
    }
  }
  &__nav-holder{
    font-size: 12px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      align-items: flex-start;
      margin: 0 54px 0 -10px;
      padding: 16px 0 0 48px;
    }
    @media (min-width: $break-large) {
      font-size: 16px;
      margin: 0 -10px;
      padding: 26px 137px 0 96px;
    }
    ul{
      @extend %listreset;
      @media (min-width: $break-medium) {
        width: 50%;
        padding: 0 10px;
      }
    }
    a{
      font-weight: normal;
      text-decoration: none;
      @media (min-width: $break-large) {
        &:hover{
          text-decoration: underline;
        }
      }
    }
    li{
      margin: 0 0 21px;
      @media (min-width: $break-medium) {
        margin: 0 0 13px;
        &:last-child{
          margin: 0;
        }
      }
      @media (min-width: $break-large) {
        margin: 0 0 11px;
      }
    }
  }
  &__contact-block{
    padding: 38px 0 11px;
    @media (min-width: $break-medium) {
      padding: 31px 0 18px;
    }
    @media (min-width: $break-large) {
      padding: 49px 0 18px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  .address-block{
    font-size: 12px;
    line-height: 1.67;
    margin: 0 0 50px;
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -10px 3px;
    }
    @media (min-width: $break-large) {
      font-size: 14px;
      line-height: 1.58;
      width: 66.4%;
      padding: 3px 0 0;
      margin: 0 -10px;
    }
    &__holder{
      margin: 0 0 30px;
      @media (min-width: $break-medium) {
        width: 33.333333%;
        padding: 0 10px;
      }
      &:last-child{
        margin: 0;
      }
    }
    &__box{
      position: relative;
      padding: 0 0 0 32px;
      &:before{
        content: "\e907";
        font-family: 'icomoon';
        position: absolute;
        top: -4px;
        left: -2px;
        font-size: 24px;
        line-height: 1;
        color: $color-red;
      }
    }
  }
  address{
    font-style: normal;
  }
  &__heading{
    display: block;
    font: 600 12px/1.2 $base-font;
    letter-spacing: .23px;
    margin: 0 0 6px;
    @media (min-width: $break-large) {
      font-size: 16px;
      margin: 0 0 8px;
    }
  }
  .social-block{
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $break-large) {
      display: block;
      width: 30%;
    }
    &__title{
      display: block;
      font: 700 14px/1.2 $base-font;
      letter-spacing: .39px;
      margin: 0 0 37px;
      @media (min-width: $break-medium) {
        margin: 0;
      }
      @media (min-width: $break-large) {
        font-size: 18px;
        margin: 0 0 33px;
      }
      a{
        display: inline-block;
        vertical-align: top;
        position: relative;
        text-decoration: none;
        &:after{
          content: "\e909";
          font: 16px/1 'icomoon';
          display: inline-block;
          vertical-align: top;
          margin: 0 0 0 14px;
          @media (min-width: $break-large) {
            font-size: 20px;
            margin: 2px 0 0 11px;
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }
  .info-block{
    background-color: #f2f2f2;
    padding: 27px 0;
    font-size: 12px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      padding: 19px 0;
    }
    @media (min-width: $break-large) {
      font-size: 15px;
      padding: 32px 0;
    }
    .container{
      @media (min-width: $break-medium) {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    &__copyright-block{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 0 13px;
      @media (min-width: $break-medium) {
        margin: 0;
      }
    }
    &__copyright{
      &:after{
        content: "|";
        display: inline-block;
        vertical-align: top;
        margin: 0 16px;
        @media (min-width: $break-medium) {
          margin: 0 29px;
        }
        @media (min-width: $break-large) {
          margin: 0 38px;
        }
      }
    }
    &__by{
      a{
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
}
.social{
  @extend %listreset;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 0 0 -10px;
  overflow: hidden;
  li{
    margin: 0 0 10px 15px;
    @media (min-width: $break-medium) {
      margin: 0 0 10px 19px;
    }
    &.instagram{
      a{
        &:before{
          content: "\e905";
        }
      }
    }
    &.twitter-icon{
      a{
        &:before{
          content: "\e904";
        }
      }
    }
    &.linkedin{
      a{
        &:before{
          content: "\e903";
        }
      }
    }
    &.youtube{
      a{
        &:before{
          content: "\e908";
        }
      }
    }
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    background-color: $color-red;
    color: $color-white;
    font-size: 0;
    line-height: 0;
    border-radius: 50%;
    text-decoration: none;
    font-weight: normal;
    transition: background-color .3s;
    &:hover{
      background-color: $dark-blue;
    }
    &:before{
      content: "\e906";
      font-family: 'icomoon';
      font-size: 24px;
      line-height: 1;
    }
  }
}
.back-to-top{
  position: absolute;
  top: 17px;
  right: -3px;
  font-size: 0;
  line-height: 0;
  background-color: $dark-blue;
  border-radius: 50%;
  border: none;
  width: 54px;
  height: 54px;
  padding: 0;
  font-weight: normal;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s;
  &:hover{
    background-color: $color-red;
  }
  @media (min-width: $break-medium) {
    top: 9px;
  }
  @media (min-width: $break-large) {
    width: 90px;
    height: 90px;
    top: 20px;
    right: -2px;
  }
  &:before{
    content: "\e90a";
    font-family: 'icomoon';
    font-size: 10px;
    line-height: 1;
    @media (min-width: $break-large) {
      font-size: 18px;
    }
  }
}