/**
 * @file
 * Events 3 Up Feature
 *
 * Contains styles for Events 3 Up content object.
 */

.events3up {
  &__intro{
    margin: 0 0 20px;
    @media (min-width: $break-medium) {
      margin: 0 0 43px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 62px;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    padding: 0 0 21px;
    margin: 0 0 23px;
    @media (min-width: $break-medium) {
      padding: 0 0 40px;
      margin: 0 0 29px;
    }
    @media (min-width: $break-large) {
      display: block;
      padding: 0 0 28px;
      margin: 0;
    }
    &:last-child{
      padding: 0;
      border: none;
      @media (min-width: $break-large) {
        padding: 0 0 28px;
        border-bottom: 1px solid $color-black;
      }
    }
  }
  &__date {
    width: 44px;
    height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-white;
    border-radius: 50%;
    background-color: $color-red;
    flex-shrink: 0;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    margin: 4px 0 0;
    @media (min-width: $break-medium) {
      width: 58px;
      height: 58px;
      font-size: 13px;
      margin: 2px 0 0 3px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 34px;
    }
  }
  .column{
    margin: 0 0 28px;
    @media (min-width: $break-medium) {
      display: block;
      margin: 0;
    }
    @media (min-width: $break-large) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__day{
    font-size: 17px;
    font-weight: 600;
    @media (min-width: $break-medium) {
      font-size: 22px;
    }
  }
  &__data{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (min-width: $break-medium) {
      font-size: 16px;
      margin: 0 0 5px;
    }
  }
  &__location{
    &:before{
      content: "|";
      display: inline-block;
      vertical-align: top;
      margin: 0 5px;
    }
  }
  &__content{
    flex-grow: 1;
    padding: 0 5px 0 18px;
    @media (min-width: $break-medium) {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 38px;
    }
    @media (min-width: $break-large) {
      padding: 0;
    }
  }
  h3 {
    margin: 0 0 5px;
    font-size: 20px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      order: 1;
      font-size: 23px;
      margin: 0;
    }
    a{
      text-decoration: none;
      font-weight: 500;
    }
  }
  &__section-cta{
    line-height: 1.2;
    @media (min-width: $break-medium) {
      margin: 0;
      position: absolute;
      top: 10px;
      right: 50px;
    }
    @media (min-width: $break-large) {
      top: 12px;
      right: 20px;
    }
    .cta{
      &--link{
        font-size: 18px;
        margin: 0;
        position: relative;
        padding: 0 27px 0 0;
        &:after{
          margin: 0;
          position: absolute;
          top: 5px;
          right: 0;
        }
        @media (min-width: $break-medium) {
          font-size: 16px;
          background: none;
          &:after{
            top: 3px;
          }
        }
        @media (min-width: $break-large) {
          font-size: 18px;
          padding: 0 25px 0 0;
          &:after{
            top: 5px;
            transition: right .3s;
          }
          &:hover{
            &:after{
              right: -4px;
            }
          }
        }
      }
    }
  }
  .container{
    position: relative;
  }
}