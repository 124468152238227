.breadcrumbs-wrapper {
  height: 43px;
  @media (min-width: $break-large) {
    height: 42px;
  }
}
.breadcrumbs{
  background-color: $color-grey;
  padding: 15px 10px 10px 19px;
  position: relative;
  line-height: 1.2;

  @media screen and (min-width: $break-medium) {
    padding: 15px 10px 10px 49px;
  }

  @media (min-width: $break-large) {
    font-size: 15px;
    padding: 0 10px 0 31px;
    letter-spacing: .28px;
  }
  .scrolled & {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
  }
  &__list{
    position: absolute;
    top: 100%;
    left: 11px;
    z-index: 1;
    width: 297px;
    background-color: $color-white;
    height: 285px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    @media (max-width: $break-large - 1) {
      filter: drop-shadow(0 30px 30px rgba($color-black, 0.3));
    }

    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      left: 50px;
    }

    @media (min-width: $break-large) {
      position: static;
      height: auto;
      width: auto;
      overflow: visible;
      background: none;
      visibility: visible;
      opacity: 1;
    }
    .open & {
      opacity: 1;
      visibility: visible;
    }
    >ul{
      padding: 35px 10px 10px 38px;

      @media (min-width: $break-large) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0;
      }
      >li{
        &.has-drop-down{
          &:last-child{
            >a{
              color: $color-black;
            }
          }
        }
        &:last-child{
          display: none;
          @media (min-width: $break-large) {
            display: block;
          }
          >a{
            color: #6E6E6E;
          }
        }
        @media (min-width: $break-large) {
          padding: 0 44px;
          position: relative;
          &:before{
            content: "/";
            position: absolute;
            top: 15px;
            left: 0;
            font-size: 15px;
            line-height: 1;
          }
          &:first-child{
            padding: 0 40px 0 0;
            &:before{
              display: none;
            }
          }
        }
        >a{
          @media (min-width: $break-large) {
            padding: 13px 0 11px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
  ul{
    @extend %listreset;
    ul{
      padding: 21px 0 0 24px;
      @media (min-width: $break-large) {
        position: absolute;
        top: 100%;
        left: 10px;
        z-index: 1;
        width: 231px;
        padding: 43px 20px 41px 43px;
        opacity: 0;
        filter: drop-shadow(0 30px 30px rgba($color-black, 0.3));
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        letter-spacing: normal;
        background-color: $color-white;
        font-size: 16px;
      }
      li{
        margin: 0 0 23px;
      }
    }
  }
  .current-item{
    a{
      font-weight: 700;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        top: 9px;
        right: -18px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-red;
      }
    }
  }
  a{
    text-decoration: none;
    font-weight: normal;
  }
  li{
    margin: 0 0 19px;
    @media (min-width: $break-large) {
      margin: 0;
    }
    &:last-child{
      margin: 0;
    }
  }
  .has-drop-down{
    padding: 0 0 0 23px;
    @media (min-width: $break-large) {
      padding: 0 32px 0 56px;
      &:hover,
      &.open {
        > ul {
          opacity: 1;
          visibility: visible;
        }
        > a {
          &:after {
            top: -1px;
            transform: none;
          }
        }
      }
    }
    >a{
      @media (min-width: $break-large) {
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        &:after {
          position: relative;
          top: 0px;
          content: '\e90a';
          font-family: 'icomoon';
          font-size: 4px;
          font-weight: 700;
          line-height: 1;
          transition: transform 0.3s, top 0.3s;
          transform: rotate(-180deg);
          margin: 0 0 0 10px;
        }
      }
    }
  }
  &__opener {
    border: none;
    background: none;
    padding: 0 16px 0 0;
    display: block;
    font: 600 15px/1.2 $base-font;
    position: relative;
    transition: color 0.3s;
    .open & {
      &:after {
        transform: none;
        top: 6px;
      }
    }
    @media (min-width: $break-large) {
      display: none;
    }
    &:after {
      content: '\e90a';
      font-family: 'icomoon';
      position: absolute;
      right: 0;
      top: 9px;
      font-size: 4px;
      font-weight: 700;
      line-height: 1;
      transition: all 0.3s;
      transform: rotate(-180deg);
    }
  }
}