/**
 * @file
 * Accordion
 *
 * Contains styles for accordion content object.
 */

.accordion {
  // Expand/collapse all wrapper
  padding: 33px 0 41px;
  margin: 0;
  @media (min-width: $break-medium) {
    padding: 43px 0 53px;
  }
  @media (min-width: $break-large) {
    padding: 81px 0 84px;
  }
  &__intro{
    margin: 0 0 21px;
    @media (min-width: $break-medium) {
      margin: 0 0 31px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 37px;
    }
  }
  &__header{
    margin: 0 0 13px;
    @media (min-width: $break-medium) {
      margin: 0 0 7px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 11px;
    }
    h2{
      margin: 0;
    }
  }
  &__all {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border-bottom: 2px solid $color-black;
    padding: 0 0 14px;
    @media (min-width: $break-medium) {
      text-align: right;
    }
    button {
      background: none;
      border: 0;
      padding: 0;
      color: $color-black;
      text-transform: uppercase;
      font: 700 13px/1.2 $base-font;
      transition: color .3s;
      &:hover{
        color: $color-red;
      }
      @media (min-width: $break-medium) {
        font-size: 14px;
      }
      @media (min-width: $break-large) {
        font-size: 16px;
      }
      &:before{
        content: "|";
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        color: $color-black;
        @media (min-width: $break-medium) {
          margin: 0 17px;
        }
        @media (min-width: $break-large) {
          margin: 0 19px;
        }
      }
      &:first-child{
        &:before{
          display: none;
        }
      }
      &.js-disabled {
        color: $color-grey2;
        pointer-events: none;
      }
    }
  }
  // Accordion item
  &__item {
    border-bottom: 1px solid $color-black;
  }
  // Item toggle
  &__toggle {
    display: block;
    position: relative;
    padding: 20px 0 15px 39px;
    width: 100%;
    background: none;
    border: none;
    color: $color-black;
    font: 18px/1.2 $base-font;
    text-align: left;
    transition: 0.3s ease-in-out;
    @media (min-width: $break-medium) {
      padding: 30px 0 14px 59px
    }
    @media (min-width: $break-large) {
      font-size: 20px;
      padding: 28px 5px 14px 59px;
    }
    &:hover{
      .accordion__icon{
        &:before,
        &:after {
          background-color: $color-black;
        }
      }
    }
    &:focus {
      outline-width: 2px;
    }
    // Expanded toggle styling
    &.js-expanded {
      .accordion__icon:before {
        height: 0;
      }
    }
  }
  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    margin: 3px 0 0;
    @media (min-width: $break-medium) {
      left: 12px;
      width: 23px;
      height: 23px;
      margin: 8px 0 0;
    }
    @media (min-width: $break-large) {
      width: 22px;
      height: 22px;
      left: 12px;
      margin: 7px 0 0;
    }
    // Psuedo elements to make up +/-
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: $color-red;
      content: '';
      transition: 0.3s ease-in-out;
    }
    &:before {
      left: calc(0.5em - 1px);
      height: 100%;
      width: 2px;
      @media (min-width: $break-medium) {
        left: calc(0.7em - 3px);
        width: 3px;
      }
      @media (min-width: $break-large) {
        left: 0.5em;
      }
    }
    &:after {
      height: 2px;
      width: 100%;
      @media (min-width: $break-medium) {
        height: 3px;
      }
    }
  }
  &__content {
    display: none;
    padding: 7px 0 17px;
    @media (min-width: $break-medium) {
      padding: 7px 0 11px;
    }
    @media (min-width: $break-large) {
      padding: 23px 0 21px;
    }
    .views-element-container {
      width: 100%;
    }
    ul{
      padding: 0;
      margin: 0 0 20px;
      list-style: none;
      font-size: 14px;
      line-height: 1.72;
      @media (min-width: $break-medium) {
        margin: 0 0 41px;
      }
      @media (min-width: $break-large) {
        font-size: 15px;
        margin: 0 0 19px;
      }
      a{
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
  &__copy {
    width: 100%;
    .table--wysiwyg {
      margin: 0;
      width: 100%;
      @media (max-width: $break-medium - 1px) {
        margin: 0 1em;
        width: calc(100% + 2em);
      }
    }
  }
  &__top {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    text-transform: uppercase;
    font: 700 13px/1.2 $base-font;
    transition: color .3s;
    @media (min-width: $break-medium) {
      font-size: 14px;
    }
    @media (min-width: $break-large) {
      font-size: 16px;
    }
    &:hover{
      color: $color-red;
    }
  }
}