/**
 * @file
 * Columns
 *
 * Base styles for all column layouts.
 */

.column {
  font-size: 14px;
  line-height: 1.72;
  @media (min-width: $break-medium) {
    display: flex;
  }
  @media (min-width: $break-large) {
    font-size: 15px;
    line-height: 1.67;
  }
  &__col {
    margin: 0 0 20px;
    padding: 0 0 22px;
    border-bottom: 1px solid $color-black;
    &:last-child{
      margin: 0;
    }
    @media (min-width: $break-medium) {
      margin: 0;
      flex: 0;
    }
    @media (min-width: $break-large) {
      padding: 0 0 32px;
    }
  }
  &__img {
    margin: 0 0 10px;
    @media (min-width: $break-large) {
      margin: 0 0 14px;
    }
    img {
      width: 100%;
    }
  }
  &__title {
    margin: 0 0 11px;
    @media (min-width: $break-large) {
      margin: 0 0 13px;
    }
    h3 {
      margin: 0;
      font-size: 19px;
      line-height: 1.2;
      @media (min-width: $break-medium) {
        font-size: 21px;
      }
      @media (min-width: $break-large) {
        font-size: 25px;
      }
    }
    a{
      text-decoration: none;
      &:after{
        content: "\e900";
        font-family: 'icomoon';
        font-size: 12px;
        line-height: 1;
        font-weight: normal;
        color: $color-red;
        margin: 0 0 0 10px;
        transition: margin .3s;
      }
      @media (min-width: $break-large) {
        &:hover{
          &:after{
            margin: 0 0 0 14px;
          }
        }
      }
    }
  }
  &__subtitle {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 1.04px;
    margin: 0 0 14px;
    @media (min-width: $break-medium) {
      margin: 0 0 11px;
    }
    @media (min-width: $break-large) {
      font-size: 15px;
      letter-spacing: 1.3px;
      margin: 0 0 18px;
    }
  }
}
.column--two {
  @media (min-width: $break-medium) {
    justify-content: space-between;
  }
  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 20px); // 50% - half container gutter
    }
    @media (min-width: $break-large) {
      flex-basis: calc(50% - 25px); // 50% - half container gutter
    }
  }
}
.column--three {
  @media (min-width: $break-medium) {
    justify-content: space-between;
  }
  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc((100% / 3) - (20px / 3)); // 33.33% - 1/3 container gutter
    }
    @media (min-width: $break-large) {
      flex-basis: calc((100% / 3) - (50px / 3)); // 33.33% - 1/3 container gutter
    }
  }
  &.slick-slider {
    display: block;
  }
}