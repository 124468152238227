/**
 * @file
 * Story Display
 *
 * Contains styles for Story Display object.
 */

.story-display {
  position: relative;
  padding: 30px 0 11px;
  @media (min-width: $break-medium) {
    padding: 45px 0 23px;
  }
  @media (min-width: $break-large) {
    padding: 99px 0 33px;
  }
  &__header{
    @media (min-width: $break-medium) {
      margin: 0 0 29px;
    }
  }
  &:before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-color: $color-grey;
    z-index: -1;
    @media (min-width: $break-large) {
      height: 607px;
    }
  }
  &__intro{
    margin: 0 0 19px;
    @media (min-width: $break-medium) {
      margin: 0 0 41px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 47px;
    }
  }
  &__title{
    letter-spacing: .26px;
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 4px;
    @media (min-width: $break-large) {
      font-size: 16px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 1px;
      letter-spacing: .3px;
    }
    a{
      color: $color-red;
      font-weight: 600;
      text-decoration: none;
      &:hover{
        color: $dark-blue;
      }
    }
  }
  &__wrap {
    @media (min-width: $break-medium) {
      display: flex;
    }
  }
  &__img {
    margin: 0 0 29px;
    @media (min-width: $break-medium) {
      margin: 0;
      margin-bottom: -55px;
      flex: 0 0 46.6%;
    }
    @media (min-width: $break-large) {
      flex: 0 0 46.1%;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        width: 349px;
        height: 41px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
      }
    }
    img {
      width: 100%;
    }
  }
  &__content {
    @media (min-width: $break-medium) {
      flex: 0 0 53.4%;
      padding: 14px 0 0 40px;
    }
    @media (min-width: $break-large) {
      flex: 0 0 53.9%;
      padding: 62px 0 0 58px;
    }
  }
  &__quote {
    font-size: 22px;
    line-height: 1.37;
    @media (min-width: $break-large) {
      font-size: 26px;
      line-height: 1.31;
    }
  }
  &__subtitle {
    font-size: 14px;
    letter-spacing: .26px;
    margin: 0 0 22px;
    @media (min-width: $break-large) {
      font-size: 16px;
      letter-spacing: .3px;
      margin: 0 0 50px;
    }
  }
}
