/**
 * Variables
 * Contains global variables available to the theme.
 */

//
// Fonts
$base-font: 'Work Sans', Arial, Helvetica, sans-serif;
//

/// Arial
$font-arial: Arial, Helvetica, sans-serif;

//
// Breakpoints
//
$break-x-large: 1201px;
$break-large: 1025px;
$break-medium: 700px;

// Content
$content-max: 1900px;

//
// Colors
//
$color-white: #ffffff;
$color-black: #000000;
$color-red: #cc0a2f;
$color-grey: #f2f2f2;
$color-grey2: #757575;
$dark-blue: #182033;
$color-red-light: #ff9999;
$color-green: #006600;
$color-green-light: #BBFBB3;
$color-orange: #CC6600;
$color-orange-light: #FFCC66;
$color-alert-major: #ff6068;
$color-alert-medium: #f7b500;
$color-alert-minor: #44D7B6;
