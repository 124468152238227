.events-section{
  padding: 12px 0;
  @media (min-width: $break-medium) {
    padding: 20px 0 27px;
  }
  @media (min-width: $break-large) {
    padding: 0;
  }
  .container{
    max-width: 1040px;
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
    }
  }
  h2{
    font-size: 30px;
    @media (min-width: $break-medium) {
      font-size: 40px;
      line-height: 1.19;
    }
    @media (min-width: $break-large) {
      font-size: 50px;
    }
  }
  &__header{
    margin: 0 0 11px;
    @media (min-width: $break-medium) {
      margin: 0 0 23px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 14px;
    }
  }
  &__intro{
    margin: 0 0 21px;
    line-height: 1.5;
    @media (min-width: $break-medium) {
      margin: 0 0 29px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 46px;
    }
  }
  &__events-list{
    @extend %listreset;
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 32px;
    @media (min-width: $break-medium) {
      margin: 0;
    }
    @media (min-width: $break-large) {
      font-size: 16px;
      margin: 0 0 66px;
    }
    li{
      display: flex;
      align-items: flex-start;
      padding: 0 0 25px;
      margin: 0 0 23px;
      border-bottom: 1px solid $color-black;
      @media (min-width: $break-medium) {
        padding: 0 0 29px;
        margin: 0 0 23px;
      }
      @media (min-width: $break-large) {
        padding: 0 0 36px;
        margin: 0 0 26px;
      }
      &:last-child{
        padding: 0;
        border: none;
        margin: 0;
      }
    }
  }
  &__date {
    width: 44px;
    height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-white;
    border-radius: 50%;
    background-color: $color-red;
    flex-shrink: 0;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    margin: 4px 0 0;
    @media (min-width: $break-medium) {
      width: 46px;
    }
    @media (min-width: $break-large) {
      width: 58px;
      height: 58px;
    }
  }
  &__day{
    font-size: 17px;
    font-weight: 600;
  }
  &__content{
    flex-grow: 1;
    padding: 0 5px 0 18px;
    @media (min-width: $break-medium) {
      padding: 8px 0 0 35px;
    }
    @media (min-width: $break-large) {
      padding: 2px 0 0 39px;
      display: flex;
      flex-direction: column;
    }
  }
  h3 {
    margin: 0 0 9px;
    font-size: 20px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      margin: 0 0 13px;
    }
    @media (min-width: $break-large) {
      order: 1;
      font-size: 23px;
      margin: 0;
    }
    a{
      text-decoration: none;
      font-weight: 500;
    }
  }
  &__data{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (min-width: $break-large) {
      margin: 0 0 9px;
    }
  }
  &__location{
    &:before{
      content: "|";
      display: inline-block;
      vertical-align: top;
      margin: 0 5px;
    }
  }
  &__section-cta{
    line-height: 1.2;
    @media (min-width: $break-medium) {
      position: absolute;
      bottom: -2px;
      left: 50%;
      margin: 0 0 0 7.4%;
    }
    @media (min-width: $break-large) {
      position: static;
      margin: 0;
    }
    .cta{
      font-size: 18px;
      margin: 0;
    }
  }
  &__text-block{
    margin: 0 0 32px;
    @media (min-width: $break-medium) {
      width: 50%;
      margin: 0;
    }
    @media (min-width: $break-large) {
      width: 44%;
    }
  }
  &__img-block{
    font-size: 12px;
    line-height: 1.59;
    @media (min-width: $break-medium) {
      width: 41.4%;
      padding: 7px 0 60px;
    }
    @media (min-width: $break-large) {
      padding: 13px 0 40px;
    }
    img{
      margin: 0 0 12px;
      @media (min-width: $break-medium) {
        margin: 0 0 10px;
      }
      @media (min-width: $break-large) {
        margin: 0 0 15px;
      }
    }
  }
  &__caption{
    position: relative;
    padding: 0 0 14px;
    &:after{
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 244px;
      height: 29px;
      background-image: radial-gradient(circle at 48% -165px, #eabfc0, #ffffff 200px);
      @media (min-width: $break-large) {
        width: 349px;
        height: 41px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
      }
    }
  }
}