/**
 * @file
 * Instagram
 *
 * Contains styles for instagram object.
 */

.instafeed {
  @media screen and (min-width: $break-medium) {
    position: relative;
  }
  @media screen and (min-width: $break-large) {
    max-width: 1000px;
    margin: 0 auto 80px;
  }
  .container{
    @media (min-width: $break-medium) {
      position: relative;
    }
  }
}
.instagram {
  &__tile {
    position: relative;
    padding-top: 91%;
    border: none;
    @media screen and (min-width: $break-medium) {
      padding-top: 28.51%;
    }
    @media (min-width: $break-large) {
      padding-top: 27.4%;
    }
  }
  &__title {
    font: 700 21px/1.2 $base-font;
    position: relative;
    margin: 0 0 29px;
    @media (min-width: $break-medium) {
      font-size: 24px;
      margin: 0 0 37px;
    }
    @media (min-width: $break-large) {
      font-size: 27px;
      margin: 0 0 56px;
    }
    span{
      display: inline-block;
      padding: 0 34px 0 70px;
      vertical-align: top;
      background-color: $color-white;
      @media (min-width: $break-medium) {
        padding: 0 28px 0 79px;
      }
      @media screen and (min-width: $break-large) {
        padding-right: 60px;
      }
    }
    &:before{
      content: "";
      position: absolute;
      top: -12px;
      left: 0;
      background-image: url(../assets/icons/instagram-color.svg);
      width: 43px;
      height: 43px;
      @media (min-width: $break-medium) {
        top: -10px;
      }
      @media (min-width: $break-medium) {
        top: -7px;
      }
    }
    &:after{
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 12px;
      z-index: -1;
      height: 2px;
      background-color: $color-black;
      @media (min-width: $break-medium) {
        top: 14px;
      }
      @media (min-width: $break-large) {
        top: 17px;
      }
    }
  }
  &__feed {
    padding: 0 0 16px;
    margin: 0 0 33px;
    @media (min-width: $break-medium) {
      padding: 0;
      margin: 0;
    }
    &:after{
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 21px;
      background-image: radial-gradient(circle at 48% 0, #eabfc0, #ffffff 90%);
      opacity: .2;
      @media (min-width: $break-medium) {
        display: none;
      }
    }
    .slick {
      &-slide {
        width: 260px;
        margin: 0 0 0 15px;
        & > div {
          font-size: 0;
          line-height: 0;
        }
      }
      &-dots{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        font-size: 0;
        line-height: 0;
        padding: 0 24px 0 0;
        @media (min-width: $break-large) {
          position: absolute;
          bottom: 23px;
          right: 50%;
          margin: 0 -250px 0 0;
        }
        button{
          width: 18px;
          height: 18px;
          border-radius: 50%;
          &:after{
            width: 4px;
            height: 4px;
            background-color: #182033;
          }
        }
      }
      &-arrow{
        top: auto;
        bottom: 16px;
        transform: none;
        width: auto;
        height: auto;
        color: $color-black;
        text-indent: 0;
        &:after{
          content: "\e909";
          font-family: 'icomoon';
          display: block;
          font-size: 20px;
          line-height: 1;
          position: static;
          height: auto;
          width: auto;
          background: none;
          transform: none;
        }
        &.slick-prev{
          &:after{
            transform: rotate(-180deg);
          }
        }
        &.slick-next{
          right: 22px;
        }
        &.slick-disabled{
          &:after{
            background: none;
          }
        }
      }
      &-list{
        margin: 0 0 18px;
        overflow: visible;
      }
    }
  }
  &__link {
    @include ir;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    transition: opacity 0.3s ease;
    &:after {
      content: '';
      position: absolute;
      bottom: 12px;
      right: 12px;
      width: 30px;
      height: 30px;
      background-image: url(../assets/icons/instagram-white.svg);
      background-size: contain;
      @media screen and (min-width: $break-medium) {
        bottom: 7px;
        right: 7px;
        width: 25px;
        height: 25px;
      }
      @media screen and (min-width: $break-large) {
        bottom: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &__account {
    line-height: 1.2;
    @media (min-width: $break-medium) {
      position: absolute;
      top: 6px;
      right: 50px;
    }
    @media (min-width: $break-large) {
      right: 20px;
      top: 7px;
    }
    .cta{
      &--link{
        font-size: 16px;
        margin: 0;
        position: relative;
        padding: 0 30px 0 0;
        &:after{
          margin: 0;
          position: absolute;
          top: 3px;
          right: 0;
        }
        @media (min-width: $break-medium) {
          background-color: $color-white;
          padding: 0 28px 0 19px;
        }
        @media (min-width: $break-large) {
          font-size: 18px;
          padding: 0 33px 0 19px;
          &:after{
            top: 5px;
            transition: right .3s;
          }
          &:hover{
            &:after{
              right: -4px;
            }
          }
        }
      }
    }
  }
}