/**
 * @file
 * Calendar
 *
 * Contains styles for the Calendar Detail Page
 */
.event-detail {
  padding: 29px 0 38px;
  @media (min-width: $break-medium) {
    padding: 40px 0 35px;
  }
  @media (min-width: $break-large) {
    padding: 79px 0 75px;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    font-size: 13px;
    line-height: 1.2;
    @media (min-width: $break-large) {
      font-size: 16px;
    }
  }
  &__data-holder{
    padding: 0 0 0 19px;
    flex-grow: 1;
    @media (min-width: $break-medium) {
      padding: 0 0 0 54px;
    }
    @media (min-width: $break-large) {
      padding: 0 0 0 68px;
    }
  }
  &__time{
    margin: 0 0 9px;
    @media (min-width: $break-large) {
      margin: 0 0 11px;
    }
  }
  &__data {
    width: 100%;
  }
  &__tags-label,
  &__save-label {
    font: 700 16px/1.2 $base-font;
    text-transform: uppercase;
    margin: 0 0 25px;
    @media (min-width: $break-medium) {
      margin: 0 64px 0 0;
    }
  }
  &__tags-wrapper{
    font-size: 15px;
    line-height: 1.2;
    margin: 0 0 42px;
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-start;
      margin: 0 0 44px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 60px;
    }
    a{
      font-weight: normal;
      display: block;
    }
  }
  &__tags-list{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li{
      margin: 0 16px 0 0;
      @media (min-width: $break-medium) {
        margin: 0 31px 0 0;
      }
      &:last-child{
        margin: 0;
      }
    }
  }
  &__save{
    font-size: 0;
    line-height: 0;
  }
  &__save-icon{
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    line-height: 0;
    color: $color-red;
    margin: 0 18px 0 0;
    transition: color .3s;
    text-decoration: none;
    &:before{
      content: "\e90d";
      font-family: 'icomoon';
      display: block;
      font-size: 18px;
      line-height: 1;
    }
    &:hover{
      text-decoration: none;
      color: $dark-blue;
    }
  }
  .icon--google{
    &:before{
      content: "\e90c";
    }
  }
  .icon--windows{
    &:before{
      content: "\e90b";
    }
  }
  .icon--yahoo{
    &:before{
      content: "\e90e";
    }
  }
  .item{
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-start;
    }
  }
  &__date {
    text-align: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: $color-red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    margin: 5px 0 0;
    font-size: 10px;
    line-height: 1;
    @media (min-width: $break-medium) {
      width: 58px;
      height: 58px;
      margin: 11px 0 0;
      font-size: 13px;
    }
    @media (min-width: $break-large) {
      width: 73px;
      height: 73px;
      font-size: 16px;
    }
  }
  &__day {
    font-weight: 600;
    font-size: 17px;
    @media (min-width: $break-medium) {
      font-size: 22px;
    }
    @media (min-width: $break-large) {
      font-size: 27px;
    }
  }
  &__body {
    margin: 0 0 36px;
    @media (min-width: $break-medium) {
      border-bottom: 1px solid $color-black;
      padding: 0 0 45px;
      margin: 0 0 47px;
    }
    @media (min-width: $break-large) {
      padding: 0 0 64px;
    }
  }
  &__month {
    text-transform: uppercase;
  }
}