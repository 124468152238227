/**
 * @file
 * Carousel
 *
 * Contains styles for carousel object.
 */

 .carousel {
  padding: 56px 0 0;
  margin: 0;
  @media (min-width: $break-medium) {
    padding: 80px 0 92px;
  }
  @media (min-width: $break-large) {
    padding: 71px 0 87px;
  }
  .container{
    @media screen and (min-width: $break-medium) {
      position: relative;
    }

    &:before {
      @media screen and (min-width: $break-medium) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background-color: $color-white;
        z-index: 1;
      }

      @media screen and (min-width: $break-large) {
        left: calc(-1 * (100vw - 940px) / 2);
        width: calc((100vw - 1000px) / 2);
      }
    }
  }

  &__header{
    @media (min-width: $break-medium) {
      margin: 0 0 10px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 8px;
    }
    h3{
      @media (min-width: $break-medium) {
        font-size: 19px;
      }
      @media (min-width: $break-large) {
        font-size: 20px;
      }
    }
  }

  &__intro{
    margin: 0 0 7px;
    @media (min-width: $break-medium) {
      margin: 0 0 37px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 47px;
    }
  }

  &__item {
    padding: 0 0 168px;
    @media (min-width: $break-medium) {
      display: flex !important;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 0 65px;
    }
  }
  &__wrapper{
    &:after{
      content: "";
      position: absolute;
      bottom: 71px;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 21px;
      background-image: radial-gradient(circle at 48% -170px, #eabfc0, #ffffff 200px);
      @media (min-width: $break-medium) {
        width: 280px;
        height: 41px;
        background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
        transform: none;
        left: 0;
        bottom: auto;
        top: 100%;
      }
      @media (min-width: $break-large) {
        width: 349px;
        height: 41px;
      }
    }
  }
  &__content{
    @media (min-width: $break-medium) {
      width: 52.8%;
      padding: 41px 0 0 40px;
      font-size: 22px;
      line-height: 1.32;
    }
    @media (min-width: $break-large) {
      font-size: 26px;
      line-height: 1.31;
      width: 45.8%;
      padding: 35px 0 0 58px;
    }
  }
  &__img {
    padding-top: 104%;
    position: relative;
    @media (min-width: $break-medium) {
      padding-top: 47.3%;
      width: 47.2%;
    }
    @media (min-width: $break-large) {
      padding-top: 54.3%;
      width: 54.2%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .slick-arrow{
    top: auto;
    bottom: 112px;
    transform: none;
    width: auto;
    height: auto;
    color: $color-black;
    text-indent: 0;
    @media (min-width: $break-medium) {
      width: 66px;
      height: 66px;
      left: 215px;
      bottom: 0;
    }
    @media (min-width: $break-large) {
      left: 349px;
    }
    &:after{
      content: "\e909";
      font-family: 'icomoon';
      display: block;
      font-size: 20px;
      line-height: 1;
      position: static;
      height: auto;
      width: auto;
      background: none;
      transform: none;
    }
    &.slick-prev{
      @media (min-width: $break-medium) {
        left: 50px;
      }
      @media (min-width: $break-large) {
        left: 190px;
      }
      &:after{
        transform: rotate(-180deg);
      }
    }
    &.slick-disabled{
      &:after{
        background: none;
      }
      @media (min-width: $break-medium) {
        background: $dark-blue;
        color: $color-white;
      }
    }
  }
  .slick-dots{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 112px;
    left: 0;
    width: 100%;
    @media (min-width: $break-medium) {
      position: absolute;
      bottom: 23px;
      left: 140px;
      width: auto;
    }
    @media (min-width: $break-large) {
      left: 275px;
    }
    button{
      width: 18px;
      height: 18px;
      border-radius: 50%;
      &:after{
        width: 4px;
        height: 4px;
        background-color: #182033;
      }
    }
  }
  .cta{
    &--button{
      margin: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      @media (min-width: $break-medium) {
        position: static;
      }
    }
  }
  .slick-list{
    @media (min-width: $break-medium) {
      overflow: visible;
    }
  }
  .slick-track{
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !important;
    display: -o-flex !important;
    display: flex !important;
    @media (min-width: $break-medium) {
      display: block !important;
    }
  }
  .slick-slide{
    height: inherit !important;
    @media (min-width: $break-medium) {
      width: 595px;
      margin: 0 0 0 35px;
      height: 100% !important;
    }
    @media (min-width: $break-large) {
      width: 765px;
      margin: 0 0 0 138px;
    }
  }
}