/**
 * @file
 * Statistic: 3 up feature
 *
 * Styles for Statistick: 3 up feature object.
 */

.stat3up {
  background-color: $dark-blue;
  color: $color-white;
  padding: 36px 0 31px;
  @media (min-width: $break-medium) {
    padding: 62px 0;
  }
  @media (min-width: $break-large) {
    padding: 96px 0 100px;
    margin: 0 0 105px;
  }
  .index &{
    padding: 71px 0 55px;
    @media (min-width: $break-medium) {
      padding: 59px 0 64px;
    }
    @media (min-width: $break-large) {
      padding: 96px 0 100px;
    }
  }
  &__header{
    margin: 0 0 32px;
    @media (min-width: $break-large) {
      margin: 0 0 57px;
    }
    .index &{
      @media (min-width: $break-medium) {
        margin: 0 0 29px;
      }
      @media (min-width: $break-large) {
        margin: 0 0 57px;
      }
    }
  }
  &__item{
    border: solid $color-white;
    border-width: 1px 0 0;
    padding: 41px 0 0;
    margin: 0 0 31px;
    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-start;
      margin: 0 0 40px;
      padding: 40px 0 0;
    }
    @media (min-width: $break-large) {
      display: block;
      margin: 0;
      padding: 41px 10px 0 0;
    }
    &:last-child{
      margin: 0;
    }
  }
  &__number{
    font: 60px/1 Anton, Arial, Helvetica, sans-serif;
    margin: 0 0 9px;
    @media (min-width: $break-medium) {
      margin: 0;
    }
    @media (min-width: $break-large) {
      margin: 0 0 9px;
    }
  }
  &__content{
    @media (min-width: $break-medium) {
      padding: 2px 0 0 50px;
    }
    @media (min-width: $break-large) {
      padding: 0;
    }
  }
  &__caption{
    margin: 0 0 31px;
    @media (min-width: $break-medium) {
      margin: 0 0 9px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 15px;
    }
  }
  .column{
    font-size: 18px;
    line-height: 1.5;
    @media (min-width: $break-medium) {
      display: block;
    }
    @media (min-width: $break-large) {
      display: flex;
    }
    .stat3up{
      &__item{
        flex-basis: calc((100% / 3) - (60px / 3));
        .index &{
          @media (min-width: $break-large) {
            flex-basis: calc((100% / 3) - (160px / 3));
          }
        }
      }
    }
  }
  .cta{
    &--link{
      font-size: 18px;
      color: $color-white;
      margin: 0;
      letter-spacing: .5px;
      &:after{
        color: $color-red;
      }
      &:hover{
        color: $color-red;
      }
    }
  }
  .container{
    position: relative;
    .index &{
      max-width: 1040px;
    }
  }
  &__section-cta{
    display: none;
    @media (min-width: $break-medium) {
      margin: 0;
      line-height: 1.2;
      display: block;
      position: absolute;
      top: 10px;
      right: 50px;
    }
    @media (min-width: $break-large) {
      top: 12px;
      right: 20px;
    }
    .index &{
      @media (min-width: $break-medium) {
        top: 20px;
      }
      @media (min-width: $break-large) {
        top: 12px;
      }
    }
    .cta{
      &--link{
        @media (min-width: $break-medium) {
          font-size: 16px;
          margin: 0;
          position: relative;
          padding: 0 23px 0 0;
          background: none;
          &:after{
            margin: 0;
            position: absolute;
            top: 3px;
            right: 0;
          }
        }
        @media (min-width: $break-large) {
          font-size: 18px;
          padding: 0 24px 0 0;
          &:after{
            top: 5px;
            transition: right .3s;
          }
          &:hover{
            &:after{
              right: -4px;
            }
          }
        }
      }
    }
  }
  h2{
    .index &{
      @media (min-width: $break-medium) {
        font-size: 40px;
      }
    }
  }
}