.header{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  transition: padding .3s;
  @media screen and (min-width: $break-medium) {
    padding-top: 0 !important;
  }
  .overlay{
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
  }
  &.nav-expanded{
    .nav-opener{
      &__icon{
        span{
          opacity: 0;
        }
        &:after,
        &:before{
          transform: rotate(45deg);
          top: 6px;
          left: 1px;
          right: 1px;
        }
        &:after{
          transform: rotate(-45deg);
        }
      }
    }
    .overlay{
      @media (min-width: $break-medium) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__holder{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 15px;
    background-color: $dark-blue;
    @media (min-width: $break-medium) {
      padding: 20px 9px 15px;
    }
    @media (min-width: $break-large) {
      padding: 0;
    }
    @media (min-width: 1200px) {
      padding: 0 36px;
    }
  }
  .search-form{
    display: none;
    position: absolute;
    top: -61px;
    right: 0;
    left: 0;
    @media (min-width: $break-medium) {
      top: 59px;
      left: 169px;
      filter: drop-shadow(0 30px 30px rgba($color-black, 0.3));
    }
    @media (min-width: $break-large) {
      top: 70px;
      left: 215px;
    }
  }
  .nav-block{
    color: $color-white;
    font: 500 17px/1.2 $base-font;
    &__holder{
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 1;
      @media (min-width: $break-medium) {
        width: 320px;
        left: auto;
      }
      @media (min-width: $break-large) {
        display: block;
        position: static;
        width: auto;
      }
      @media screen and (max-width: $break-large - 1) {
        overflow-y: auto;
        height: calc(100vh - 59px);
      }
    }
  }
  .logo{
    width: 135px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    @media (min-width: $break-medium) {
      width: 169px;
      z-index: 1;
    }
    @media (min-width: $break-large) {
      width: 215px;
    }
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 106px;
      @media (min-width: $break-medium) {
        width: 132px;
      }
      @media (min-width: $break-large) {
        width: 168px;
        transition: opacity .3s, visibility .3s;
      }
      &.logo-hover{
        opacity: 0;
        visibility: hidden;
      }
    }
    a{
      display: block;
      height: 88px;
      background-color: $dark-blue;
      position: relative;
      @media (min-width: $break-medium) {
        height: 111px;
      }
      @media (min-width: $break-large) {
        height: 140px;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $color-white;
          transform: scaleX(0);
          transform-origin: 100% 50%;
          transition: transform .3s;
        }
      }
    }
  }
}

.main-nav{
  background-color: $dark-blue;
  padding: 64px 0 39px;
  @media (min-width: $break-medium) {
    padding: 28px 0 39px;
  }
  @media (min-width: $break-large) {
    padding: 0;
  }
  >ul{
    @extend %listreset;
    letter-spacing: .32px;
    @media (min-width: $break-large) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    >li{
      position: relative;
      padding: 0 0 0 63px;
      @media (min-width: $break-large) {
        padding: 0;
        margin: 0 0 0 20px;
        &:after{
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 7px;
          background-color: $color-red;
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s, visibility .3s;
        }
        &:hover,
        &.js-hover {
          &:after{
            opacity: 1;
            visibility: visible;
          }
          ul{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      @media (min-width: 1200px) {
        margin: 0 0 0 45px;
      }
      &:first-child{
        margin: 0;
      }
      &:last-child{
        @media (min-width: $break-large) {
          ul{
            left: auto;
            right: -76px;
          }
        }
        @media (min-width: 1200px) {
          ul{
            right: -102px;
          }
        }
      }
      >a{
        padding: 18px 10px 18px 32px;
        position: relative;
        transition: background-color .3s, color .3s;
        @media (min-width: $break-large) {
          padding: 27px 0 23px;
          display: block;
        }
        &:before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          background-color: rgba(255, 255, 255, .5);
          transition: opacity .3s, visibility .3s;
          @media (min-width: $break-large) {
            display: none;
          }
        }
      }
    }
    ul{
      display: none;
      background-color: $color-white;
      @extend %listreset;
      font-size: 16px;
      padding: 10px 0;
      letter-spacing: normal;
      @media (min-width: $break-large) {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        position: absolute;
        top: 100%;
        left: -102px;
        z-index: 1;
        width: 372px;
        filter: drop-shadow(0 30px 30px rgba($color-black, 0.3));
      }
      a{
        color: $color-black;
        font-weight: normal;
        padding: 10px 10px 10px 30px;
        &:hover{
          color: $color-red;
        }
      }
    }
  }
  a{
    text-decoration: none;
    color: $color-white;
    font-weight: 500;
    display: block;
    @media (min-width: $break-large) {
      display: inline-block;
      vertical-align: top;
    }
  }
  &__opener{
    background: none;
    border: none;
    padding: 0;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 63px;
    transition: background-color .3s;
    @media (min-width: $break-large) {
      display: none;
    }
    &.active-item{
      background-color: $color-red;
      &:after{
        opacity: 0;
        visibility: hidden;
      }
      & + a{
        background-color: $color-red;
        &:before{
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &:before,
    &:after{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      height: 2px;
      width: 18px;
      background-color: $color-white;
    }
    &:after{
      width: 2px;
      height: 18px;
      transition: opacity .3s, visibility .3s;
    }
  }
}
.nav-opener{
  border: none;
  background: none;
  padding: 0;
  color: $color-white;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (min-width: $break-large) {
    display: none;
  }
  &:hover{
    .nav-opener__icon{
      &:before,
      &:after,
      span {
        background: $color-red;
      }
    }
  }
  &__icon{
    width: 20px;
    height: 14px;
    position: relative;
    margin: 0 0 0 12px;
    transition: color .3s;
    &:before,
    &:after,
    span{
      background: $color-white;
      position:absolute;
      top: 6px;
      left: 0;
      right: 0;
      height: 2px;
      transition: .3s;
    }
    &:before,
    &:after{
      content:'';
      top: 0;
    }
    &:after{
      top: 12px;
    }
  }
}
.search-opener{
  border: none;
  background: none;
  width: 66px;
  height: 24px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  position: relative;
  color: $color-white;
  margin: 0 0 0 23px;
  transition: color .3s;
  @media (min-width: $break-large) {
    margin: 0 0 0 10px;
  }
  @media (min-width: 1200px) {
    margin: 0 0 0 23px;
  }
  &:hover{
    color: $color-red;
  }
  &:before{
    content: "\e901";
    font-family: 'icomoon';
    font-size: 23px;
    line-height: 1;
    @media (min-width: $break-large) {
      font-weight: 600;
    }
    .search-expanded & {
      content: "\e902";
      font-size: 14px;
      @media (min-width: $break-large) {
        font-size: 19px;
      }
    }
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    background-color: $color-white;
    @media (min-width: $break-large) {
      display: none;
    }
  }
}
.search-form{
  position: relative;
  @media (min-width: $break-large) {
    font-size: 20px;
  }
  input[type="search"]{
    padding: 5px 80px 5px 20px;
    margin: 0;
    display: block;
    width: 100%;
    height: 61px;
    border: none;
    font-family: $base-font;
    @include placeholder{
      color: $color-black;
    }
    @media (min-width: $break-medium) {
      height: 52px;
    }
    @media (min-width: $break-large) {
      height: 70px;
      padding: 5px 150px 5px 45px;
    }
  }
  button[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    width: 78px;
    height: 61px;
    border: none;
    padding: 0;
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    @media (min-width: $break-medium) {
      height: 52px;
    }
    @media (min-width: $break-large) {
      height: 70px;
      width: 138px;
    }
    &:after{
      content: "\e901";
      font-family: 'icomoon';
      font-size: 24px;
      line-height: 1;
      color: $color-red;
    }
    &:before{
      display: none;
    }
  }
}
.alert + .header .fab-links-sidepanel{
  opacity: 0;
  transition: opacity .3s .3s, top .3s;
  &.js-show{
    opacity: 1;
  }
}
.fab-links{
  @extend %listreset;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;

  @media screen and (min-width: $break-large) {
    display: none;
  }
  a{
    background-color: $color-red;
    display: block;
    text-decoration: none;
    color: $color-white;
    font-weight: 500;
    padding: 33px 10px;
    position: relative;
    @media (min-width: $break-large) {
      padding: 7px 10px 7px 16px;
      position: relative;
      transform: perspective(1px) translateZ(0);
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $dark-blue;
        transform: scaleX(0);
        transform-origin: 50%;
        transition: .3s;
      }
      &:hover{
        &:before{
          transform: scaleX(1);
        }
      }
    }
    &:after{
      content: "";
      position: absolute;
      top: 0;
      right: 15px;
      left: 15px;
      height: 1px;
      background-color: #cecece;
      @media (min-width: $break-large) {
        display: none;
      }
    }
  }
  li{
    @media (min-width: $break-large) {
      margin: 0 0 7px;
      &:last-child{
        margin: 0;
      }
    }
    &:first-child{
      a{
        &:after{
          display: none;
        }
      }
    }
  }

  &-sidepanel {
    @extend %listreset;
    text-align: center;
    position: fixed;
    z-index: 0;
    top: 133px;
    right: 0;
    font-size: 14px;
    width: 92px;
    text-align: left;
    filter: drop-shadow(0 30px 30px rgba($color-black, 0.3));
    .alert-scrolled & {
      top: 100px !important;
      @media (min-width: $break-large) {
        top: 133px !important;
      }
    }
    a{
      background-color: $color-red;
      display: block;
      text-decoration: none;
      color: $color-white;
      font-weight: 500;
      position: relative;
      padding: 7px 10px 7px 16px;
      position: relative;
      transform: perspective(1px) translateZ(0);
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $dark-blue;
        transform: scaleX(0);
        transform-origin: 50%;
        transition: .3s;
      }
      &:hover{
        &:before{
          transform: scaleX(1);
        }
      }
      &:after{
        display: none;
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        left: 15px;
        height: 1px;
        background-color: #cecece;
      }
    }

    li{
      margin: 0 0 7px;

      &:last-child{
        margin: 0;
      }

      &:first-child{
        a{
          &:after{
            display: none;
          }
        }
      }
    }
  }
}