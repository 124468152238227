/**
 * @file
 * Generic Block: Image list
 *
 * Styles for Generic Block: Image list object.
 */

.genimglist {
  &__intro{
    @media (min-width: $break-medium) {
      margin: 0 0 37px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 54px;
    }
  }
  &__wrap{
    font-size: 14px;
    line-height: 1.72;
    @media (min-width: $break-medium) {
      display: block;
    }
    @media (min-width: $break-large) {
      font-size: 15px;
      line-height: 1.67;
    }
  }
  &__item {
    margin: 0 0 25px;
    padding: 0 0 22px;
    border-bottom: 1px solid $color-black;
    &:last-child{
      margin: 0;
      padding: 0;
      border: none;
    }
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 0 28px;
      margin: 0 0 20px;
      &:last-child{
        margin: 0;
      }
    }
    @media (min-width: $break-large) {
      padding: 0 0 46px;
      margin: 0 0 56px;
    }
  }
  &__img {
    margin: 0 0 10px;
    @media (min-width: $break-medium) {
      margin: 0;
      width: 23.5%;
    }
    img{
      width: 100%;
    }
    @media (min-width: $break-medium) and (max-width: $break-large - 1) {
      position: relative;
      height: 0;
      padding-top: 23.5%;
    }

    @media (min-width: $break-large) {
      width: 20.7%;
    }

    img {
      @media (min-width: $break-medium) and (max-width: $break-large - 1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__content{
    @media (min-width: $break-medium) {
      width: 76.5%;
      padding: 12px 0 0 25px;
    }
    @media (min-width: $break-large) {
      width: 79.3%;
      padding: 11px 0 0 25px;
    }
  }
  &__title {
    margin: 0 0 11px;
    @media (min-width: $break-medium) {
      margin: 0 0 10px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 21px;
    }
    h3 {
      margin: 0;
      font-size: 19px;
      line-height: 1.2;
      @media (min-width: $break-medium) {
        font-size: 19px;
      }
      @media (min-width: $break-large) {
        font-size: 25px;
      }
    }
    a{
      text-decoration: none;
      &:after{
        content: "\e900";
        font-family: 'icomoon';
        font-size: 12px;
        line-height: 1;
        font-weight: normal;
        color: $color-red;
        margin: 0 0 0 10px;
        transition: margin .3s;
        @media (min-width: $break-large) {
          position: relative;
          top: -1px;
        }
      }
      @media (min-width: $break-large) {
        &:hover{
          &:after{
            margin: 0 0 0 14px;
          }
        }
      }
    }
  }
  &__subtitle {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 1.04px;
    margin: 0 0 14px;
    @media (min-width: $break-medium) {
      font-size: 15px;
      letter-spacing: 1.3px;
      margin: 0 0 13px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 13px;
    }
  }
}
