/**
 * @file
 * Generic Block: 2 Column
 *
 * Styles for Generic Block: 2 Column object.
 */

.gen2col {
  margin: 0;
  padding: 30px 0;
  @media (min-width: $break-medium) {
    padding: 57px 0;
  }
  @media (min-width: $break-large) {
    padding: 71px 0 79px;
  }
  &__holder{
    border-top: 2px solid $color-black;
    padding: 18px 0 0;
    @media (min-width: $break-medium) {
      padding: 34px 0 0;
    }
    @media (min-width: $break-large) {
      padding: 50px 0 0;
    }
  }
}