/**
 * @file
 * Program
 *
 * Contains styles for programs and program view modes.
 */
 [id="program-list"]{
  padding: 20px 0 40px;
  @media (min-width: $break-medium) {
    padding: 31px 0 60px;
  }
  @media (min-width: $break-large) {
    padding: 59px 0 80px;
  }
  .container{
    max-width: 1120px;
  }
 }
 .program-finder {
  @media (min-width: $break-medium) {
    display: flex;
    align-items: flex-start;
  }
  select{
    width: 100%;
  }
 }
.program__filters {
  display: none;
  background-color: $color-grey;
  font-size: 15px;
  line-height: 1.2;
  padding: 22px 39px 31px 22px;
  @media (min-width: $break-medium) {
    display: block;
    flex-shrink: 0;
    padding: 11px 0 0 11px;
    background: none;
    width: 188px;
  }
  @media (min-width: $break-large) {
    padding: 0;
    width: 178px;
  }
  &__holder {
    display: none;
    padding: 20px 0 0;
  }
  &__open {
    display: inline-block;
    vertical-align: top;
    background-color: $dark-blue;
    font: 14px/1.2 $base-font;
    letter-spacing: .26px;
    border: none;
    padding: 16px 45px 15px 20px;
    min-width: 159px;
    text-align: left;
    position: relative;
    color: $color-white;
    transition: min-width .3s;
    @media (min-width: $break-medium) {
      display: none;
    }
    &.active{
      min-width: 100%;
      @media (min-width: $break-medium) {
        min-width: 159px;
      }
      &:after{
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &:after{
      content: "+";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 26px;
      line-height: 1;
      font-style: normal;
    }
  }
  &__opener{
    background: none;
    border: solid $color-black;
    border-width: 0 0 1px;
    width: 100%;
    text-align: left;
    padding: 0 0 6px;
    position: relative;

    @media screen and (min-width: $break-medium) {
      padding-top: 2px;
    }

    @media (min-width: $break-large) {
      padding: 2px 15px 6px 0;
    }
    &.is-expanded{
      &:after{
        transform: rotate(180deg);
      }
    }
    &:after{
      content: "\e90a";
      font-family: 'icomoon';
      position: absolute;
      right: 15px;
      bottom: 11px;
      font-size: 4px;
      font-weight: 700;
      transform: rotate(-180deg);
      line-height: 1;
      @media (min-width: $break-medium) {
        right: 6px;
      }
    }
  }
  &__fieldset {
    border: 0;
    padding: 0;
    margin: 0 0 38px;
    &:last-child{
      margin: 0;
    }
    legend {
      font-weight: 700;
      font-size: 16px;
      margin: 0 0 19px;
    }
  }
  &__field {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0 0 18px;
    &:last-child{
      margin: 0;
    }
    input[type="radio"]{
      flex-shrink: 0;
    }
    label {
      flex-grow: 1;
      padding: 0 0 0 15px;
      outline: none;
    }
    input[type="radio"]:checked + label:after {
      content: "";
      width: 5px;
      height: 5px;
      background: $color-black;
      position: absolute;
      left: 8px;
      top: 8px;
      border-radius: 50%;
    }
  }
}
.program--list {
  padding: 33px 0 0;
  @media (min-width: $break-medium) {
    flex-grow: 1;
    padding: 0 0 0 48px;
  }
  @media (min-width: $break-large) {
    padding: 0 0 0 92px;
  }
  &__sort {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0 0 32px;
    &:after{
      content: '\e90a';
      font-family: 'icomoon';
      position: absolute;
      right: 15px;
      bottom: 18px;
      transform: rotate(-180deg);
      font-size: 4px;
      font-weight: 700;
      line-height: 1;
      @media (min-width: $break-large) {
        right: 14px;
        bottom: 17px;
      }
    }
    label{
      text-transform: uppercase;
      display: inline-block;
      vertical-align: top;
      font: 700 16px/1.2 $base-font;
      margin: 0 0 17px;
      @media (min-width: $break-medium) {
        margin: 0 0 16px;
      }
    }
    select{
      height: 34px;
      border: 1px solid $color-black;
      padding: 5px 16px 5px 11px;
      height: 41px;
    }
  }
  h2 {
    margin: 0 0 23px;
    @media (min-width: $break-medium) {
      margin: 0 0 24px;
      font-size: 28px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 26px;
      font-size: 40px;
    }
  }
  &__block {
    margin: 0 0 40px;
    @media (min-width: $break-medium) {
      margin: 0 0 72px;
    }
    @media (min-width: $break-large) {
      width: 84%;
    }
    &:last-child{
      margin: 0;
    }
  }
  &__items{
    padding: 0;
    margin: 0;
    list-style: none;
    a{
      color: $color-black;
      text-decoration: none;
      display: block;
      padding: 0 20px 14px 0;
      position: relative;
      font-weight: normal;
      @media (min-width: $break-medium) {
        padding: 0 20px 17px 0;
      }
      @media (min-width: $break-large) {
        padding: 0 60px 12px 0;
      }
      &:after{
        content: "\e900";
        font-family: 'icomoon';
        color: $color-red;
        position: absolute;
        right: 0;
        top: 9px;
        font-size: 12px;
        line-height: 1;
        margin: 0 0 0 11px;
        font-style: normal;
        @media (min-width: $break-large) {
          top: 13px;
          right: 10px;
        }
        @media (min-width: $break-large) {
          top: 7px;
          right: 0;
        }
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__item{
    border-bottom: 1px solid $color-black;
    margin: 0 0 17px;
    @media (min-width: $break-large) {
      margin: 0 0 20px;
    }
    &:last-child{
      margin: 0;
    }
  }
  h3{
    margin: 0;
    font: 18px/1.56 $base-font;
    @media (min-width: $break-large) {
      font-size: 20px;
    }
  }
  span{
    display: block;
  }
  a {
    &:hover,
    &:focus {
      color: $color-red;
    }
  }
}