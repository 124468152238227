/**
 * @file
 * Calendar List
 *
 * Contains styles for the Calendar List
 */

.calendar {
  padding: 19px 0 49px;
  @media (min-width: $break-medium) {
    padding: 23px 0 52px;
  }
  @media (min-width: $break-large) {
    padding: 56px 0 78px;
  }
  .container{
    max-width: 1120px;
    @media (min-width: $break-medium) {
      position: relative;
    }
    @media (min-width: $break-large) {
      display: flex;
      align-items: flex-start;
    }
  }
  a{
    text-decoration: none;
  }
  &__expand-filters {
    display: inline-block;
    vertical-align: top;
    background-color: $dark-blue;
    font: 14px/1.2 $base-font;
    letter-spacing: .26px;
    border: none;
    padding: 16px 45px 15px 20px;
    min-width: 159px;
    text-align: left;
    position: relative;
    color: $color-white;
    transition: min-width .3s;
    @media (min-width: $break-medium) {
      position: absolute;
      top: 0;
      right: 50px;
    }
    @media (min-width: $break-large) {
      display: none;
    }
    &.expanded{
      min-width: 100%;
      @media (min-width: $break-medium) {
        min-width: 159px;
      }
      &:after{
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &:after{
      content: "+";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 26px;
      line-height: 1;
      font-style: normal;
    }
  }
  &__left-col {
    padding: 26px 0 0;
    @media (min-width: $break-medium) {
      padding: 9px 0 0;
    }
    @media (min-width: $break-large) {
      flex-grow: 1;
      padding: 0 0 0 81px;
      max-width: 740px;
    }
  }
  &__pagination__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
    @media (min-width: $break-medium) {
      font-size: 15px;
      margin: 3px 0 0 46px;
    }
    @media (min-width: $break-large) {
      padding: 9px 5px 0 0;
    }
    li:after {
      content: '|';
      display: inline-block;
      vertical-align: middle;
      margin: 0 13px;
      @media (min-width: $break-medium) {
        margin: 0 17px;
      }
    }
    li:last-child:after {
      content: none;
    }
    a{
      color: $color-black;
      transition: color .3s;
      font-weight: normal;
      &.active{
        color: $color-red;
        font-weight: 700;
      }
      &:hover{
        text-decoration: none;
        color: $color-red;
      }
    }
  }
  &__pagination {
    margin: 0 0 39px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (min-width: $break-medium) {
      margin: 0 0 47px;
      justify-content: flex-start;
    }
    @media (min-width: $break-large) {
      margin: 0 0 79px;
      justify-content: space-between;
    }
    &__date {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 18px;
      line-height: 1.2;
      @media (min-width: $break-medium) {
        font-size: 24px;
      }
      @media (min-width: $break-large) {
        font-size: 27px;
      }
    }
    strong{
      margin: 0 7px;
      @media (min-width: $break-medium) {
        margin: 0 15px;
      }
      @media (min-width: $break-large) {
        margin: 0 16px;
      }
    }
  }
  .calendar__pagination__date{
    .icon-arrow-left,
    .icon-arrow-right{
      &:after{
        font-weight: 700;
        transition: color 0.3s;
        @media (min-width: $break-medium) {
          font-size: 10px;
        }
        @media (min-width: $break-large) {
          font-size: 11px;
        }
      }
      &:hover{
        &:after{
          color: $dark-blue;
        }
      }
    }
  }
  &__event__date {
    text-align: center;
    width: 46px;
    height: 46px;
    color: $color-white;
    font-size: 10px;
    line-height: 1;
    flex-shrink: 0;
    background-color: $color-red;
    border-radius: 50%;
    display: flex;
    font-weight: normal;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4px 0 0;
    text-transform: uppercase;
    transition: background-color .3s;
    @media (min-width: $break-medium) {
      width: 65px;
      height: 65px;
      font-size: 14px;
    }
    @media (min-width: $break-large) {
      width: 73px;
      height: 74px;
      font-size: 16px;
      margin: 0;
    }
    &:hover{
      color: $color-white;
      background-color: $dark-blue;
    }
  }
  &__date{
    font-size: 17px;
    font-weight: 600;
    @media (min-width: $break-medium) {
      font-size: 24px;
    }
    @media (min-width: $break-large) {
      font-size: 27px;
    }
  }
  &__event__time{
    margin: 0 0 6px;
    @media (min-width: $break-medium) {
      margin: 0 0 5px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 3px;
    }
  }
  &__event__text {
    flex-grow: 1;
    padding: 0 0 0 16px;
    font-size: 12px;
    line-height: 1.5;
    @media (min-width: $break-medium) {
      font-size: 13px;
      padding: 3px 0 0 32px;
    }
    @media (min-width: $break-large) {
      font-size: 16px;
      padding: 0 0 0 42px;
    }
    h2 {
      margin: 0 0 9px;
      font: 20px/1.2 $base-font;
      @media (min-width: $break-medium) {
        font-size: 21px;
        margin: 0 0 12px;
      }
      @media (min-width: $break-large) {
        font-size: 23px;
        margin: 0 0 12px;
      }
      a{
        color: $color-black;
        font-weight: 500;
        &:hover{
          color: $color-red;
        }
      }
    }
  }
  // datepicker and categories ui
  &__category-dates {
    display: none;
    background-color: $color-grey;
    padding: 11px 0 20px 21px;
    @media (min-width: $break-large) {
      display: block !important;
      width: 190px;
      flex-shrink: 0;
      padding: 7px 0 0;
      margin: 0;
      background: none;
    }
    h2{
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1.3px;
      margin: 0 0 25px;
    }
  }
  &__event {
    padding: 27px 0 28px;
    border-bottom: 1px solid $color-black;
    display: flex;
    align-items: flex-start;
    @media (min-width: $break-medium) {
      padding: 29px 0 23px;
    }
    @media (min-width: $break-large) {
      padding: 40px 0 28px;
    }
    &:first-child{
      padding: 0 0 28px;
      @media (min-width: $break-medium) {
        padding: 0 0 23px;
      }
      @media (min-width: $break-large) {
        padding: 0 0 28px;
      }
    }
    &:last-child{
      margin: 0;
    }
  }
  .mini-cal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: $break-large) {
      display: block;
    }
    &__header {
      display: inline-flex;
      margin: 0 0 17px;
      @media (min-width: $break-large) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    a{
      font-weight: normal;
    }
    .icon-arrow-left,
    .icon-arrow-right{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__dates {
      font-size: 11px;
      line-height: 1.2;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      th{
        font-weight: normal;
        border-bottom: 1px solid $color-black;
        padding: 0 0 12px;
        flex: 1;
        display: inline-block;
        vertical-align: top;
      }
      td {
        text-align: center;
        padding: 6px 0 5px;
        margin: 0 5px;
        display: inline-block;
        vertical-align: top;
        flex: 1;
        border-radius: 50%;
        @media (min-width: $break-large) {
          margin: 0 2px;
        }
      }
      tr{
        margin: 0 0 13px;
        display: flex;
        @media (min-width: $break-large) {
          margin: 0 0 11px;
        }
      }
      a{
        &:hover{
          text-decoration: none;
        }
      }
    }
    &__month{
      text-transform: uppercase;
      font: 700 15px/1.2 $base-font;
      letter-spacing: 1.25px;
      margin: 0 20px;
    }
    .events{
      background-color: $color-red;
      &.current {
        background: $color-black;
        a{
          font-weight: 700;
        }
      }
      a {
        color: $color-white;
      }
    }
  }
  .icon-arrow-left,
  .icon-arrow-right {
    position: relative;
    &:hover{
      text-decoration: none;
    }
     &:after {
      content: "\e90a";
      font-family: 'icomoon';
      font-size: 7px;
      line-height: 1;
      font-weight: normal;
      color: $color-red;
      display: block;
      transform: rotate(90deg);
    }
    &:before{
      display: none;
    }
  }
  .icon-arrow-left{
    &:after{
      transform: rotate(-90deg);
    }
  }
  .control-wrap {
    label {
      display: flex;
      align-items: flex-start;
      margin: 0 0 18px;
      position: relative;
      input[type="checkbox"] {
        width: 21px;
        height: 21px;
        flex-shrink: 0;
        border: 1px solid $color-black;
        transition: background-color .3s;
        &:checked{
          background-color: $color-black;
          &+span{
            &:before{
              opacity: 1;
            }
          }
        }
      }
      &:last-child{
        margin: 0;
      }
    }
  }
  .control-text{
    position: relative;
    flex-grow: 1;
    margin: 0 0 0 12px;
    &:before{
      transition: opacity .3s;
      content: '';
      opacity: 0;
      position: absolute;
      top: 7px;
      left: -27px;
      cursor: pointer;
      border: 1px solid $color-white;
      border-width: 0 0 2px 2px;
      width: 8px;
      height: 3px;
      transform: rotate(-45deg);
    }
  }
  .category__drop {
    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
  .datepicker-holder{
    margin: 0 0 25px;
    width: 237px;

    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      margin-top: 12px;
    }

    @media (min-width: $break-large) {
      width: 189px;
      margin: 0 0 36px;
    }
  }
  .category-calendar{
    font-size: 15px;
  }
}