/**
 * @file
 * Twitter
 *
 * Contains styles for twitter object.
 */

.twitter{
  padding: 40px 0 35px;
  @media (min-width: $break-medium) {
    padding: 55px 0 36px;
  }
  @media (min-width: $break-large) {
    padding: 35px 0 0;
  }
  .container{
    @media (min-width: $break-medium) {
      position: relative;
    }
  }
  &__feed{
    line-height: 1.43;
    margin: 0 0 39px;
    @media (min-width: $break-medium) {
      margin: 0;
    }
  }
  &__time{
    @media (min-width: $break-medium) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  h2{
    font: 700 21px/1.2 $base-font;
    position: relative;
    margin: 0 0 37px;
    @media (min-width: $break-medium) {
      font-size: 24px;
      margin: 0 0 51px;
    }
    @media (min-width: $break-large) {
      font-size: 27px;
      margin: 0 0 80px;
    }
    span{
      display: inline-block;
      padding: 0 34px 0 70px;
      vertical-align: top;
      background-color: $color-white;
      @media (min-width: $break-medium) {
        padding: 0 28px 0 79px;
      }
    }
    &:before{
      content: "";
      position: absolute;
      top: -12px;
      left: 0;
      background-image: url(../assets/img/icon-twitter.svg);
      width: 43px;
      height: 43px;
      @media (min-width: $break-medium) {
        top: -10px;
      }
      @media (min-width: $break-medium) {
        top: -7px;
      }
    }
    &:after{
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 12px;
      z-index: -1;
      height: 2px;
      background-color: $color-black;
      @media (min-width: $break-medium) {
        top: 14px;
      }
      @media (min-width: $break-large) {
        top: 17px;
      }
    }
  }
  &__title{
    display: none;
  }
  &__item{
    padding: 0;
    margin: 0 0 39px;
    border: none;
    &:last-child{
      margin: 0;
    }
    @media (min-width: $break-medium) {
      margin: 0;
      position: relative;
      padding: 0 0 30px;
      &:last-child{
        display: none;
      }
    }
    @media (min-width: $break-large) {
      &:last-child{
        display: block;
      }
    }
  }
  .column{
    &--three{
      .twitter{
        &__item{
          @media (min-width: $break-medium) {
            flex-basis: calc((100% / 2) - (60px / 2));
          }
          @media (min-width: $break-large) {
            flex-basis: calc((100% / 3) - (180px / 3));
          }
        }
      }
    }
  }
  &__body{
    margin: 0 0 20px;
    @media (min-width: $break-medium) {
      margin: 0 0 10px;
    }
    @media (min-width: $break-large) {
      margin: 0 0 29px;
    }
  }
  &__account{
    line-height: 1.2;
    @media (min-width: $break-medium) {
      position: absolute;
      top: 6px;
      right: 50px;
    }
    @media (min-width: $break-large) {
      right: 20px;
      top: 7px;
    }
    .cta{
      &--link{
        font-size: 16px;
        margin: 0;
        position: relative;
        padding: 0 30px 0 0;
        &:after{
          margin: 0;
          position: absolute;
          top: 3px;
          right: 0;
        }
        @media (min-width: $break-medium) {
          background-color: $color-white;
          padding: 0 28px 0 19px;
        }
        @media (min-width: $break-large) {
          font-size: 18px;
          padding: 0 33px 0 19px;
          &:after{
            top: 5px;
            transition: right .3s;
          }
          &:hover{
            &:after{
              right: -4px;
            }
          }
        }
      }
    }
  }
}