/**
 * @file
 * Hero
 *
 * Contains styles for hero object.
 */

.hero-section {
  height: 550px;
  background-image: url("../assets/img/hero-mobile.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  padding: 100px 0 47px;
  position: relative;
  @media (min-width: $break-medium) {
    height: 475px;
    padding: 120px 0 47px;
    background-image: url("../assets/img/hero.jpg");
  }
  @media (min-width: $break-large) {
    height: 749px;
    padding: 150px 0 142px;
  }
  &:after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    height: 21px;
    background-image: radial-gradient(circle at 48% -170px, #eabfc0, #ffffff 200px);
    @media (min-width: $break-medium) {
      width: 383px;
      height: 21px;
    }
    @media (min-width: $break-large) {
      width: 766px;
      height: 41px;
      background-image: radial-gradient(circle at 48% -230px, #eabfc0, #ffffff 280px);
    }
  }
  .container{
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: flex-end;
    max-width: none;
    @media (min-width: $break-large) {
      padding: 0 20px 0 100px;
    }
  }
  h1{
    font: 50px/1.2 Anton, Arial, Helvetica, sans-serif;
    margin: 0 0 15px;
    @media (min-width: $break-large) {
      font-size: 100px;
      margin: 0 0 27px;
    }
  }
  &__subtitle{
    display: block;
    font-size: 25px;
    line-height: 1.2;
    margin: 0 0 1px;
    @media (min-width: $break-large) {
      font-size: 50px;
      margin: 0 0 2px;
    }
  }
  .cta{
    margin: 0;
  }
}