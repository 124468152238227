/**
 * @file
 * Page introduction
 *
 * Contains styles for accordion content object.
 */

.page-intro {
  padding: 27px 0 21px;
  @media (min-width: $break-medium) {
    padding: 38px 0 29px;
  }
  @media (min-width: $break-large) {
    padding: 49px 0 70px;
  }
  &__title{
    display: block;
    font: 20px/1.4 $base-font;
    border-bottom: 1px solid $color-black;
    padding: 0 0 26px;
    margin: 0 0 23px;
    @media (min-width: $break-medium) {
      font-size: 22px;
      line-height: 1.37;
      padding: 0 0 33px;
      margin: 0 0 32px;
    }
    @media (min-width: $break-large) {
      font-size: 26px;
      line-height: 1.31;
      padding: 0 0 47px;
      margin: 0 0 60px;
    }
  }
  &_no-heading{
    h1{
      @media (min-width: $break-medium) {
        display: none;
      }
    }
  }
}